/* eslint-disable react/jsx-props-no-spreading */
/* eslint no-unneeded-ternary: "error" */
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';

function FormSelect({
  control,
  label,
  name,
  multiple,
  placeholder,
  options,
  onSelectionChanged,
  ...props
}) {
  return (
    <Controller
      defaultValue={multiple ? [] : ''}
      control={control}
      rules={{ required: props.required }}
      name={name ? name : label.toLowerCase()}
      render={({ field: { onChange, value: selectedValue }, fieldState: { invalid } }) => (
        <Form.Select
          {...props}
          label={label}
          options={options}
          multiple={multiple}
          placeholder={placeholder}
          onChange={(e, { value }) => {
            onChange(value);
            if (onSelectionChanged) onSelectionChanged(value);
          }}
          value={selectedValue}
          error={invalid}
        />
      )}
    />
  );
}

FormSelect.propTypes = {
  control: PropTypes.any.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onSelectionChanged: PropTypes.func,
  required: PropTypes.bool,
  options: PropTypes.array,
  multiple: PropTypes.bool,
};

export default FormSelect;
