import React, { useState } from 'react';
import { Button, Grid, Modal } from 'semantic-ui-react';

import styles from './Login.module.css';

import ModalEmailCambioPassword from '../ModalEmailCambioPassword/ModalEmailCambioPassword';
import FormRegister from '../FormRegister/FormRegister';
import FormLogin from '../FormLogin/FormLogin';

function Login() {
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [registrarUsuario, setRegistrarUsuario] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);

  const changeForm = () => {
    setRegistrarUsuario(!registrarUsuario);
  };

  return (
    <>
      {registerSuccess && (
        <Modal dimmer="blurring" open={registerSuccess} size="tiny">
          <Modal.Header>Usuario registrado!</Modal.Header>
          <Modal.Content>
            Usuario registrado correctamente. Compruebe su casilla de correo electronico para
            verificarlo
          </Modal.Content>
          <Modal.Actions>
            <Button color="violet" onClick={() => setRegisterSuccess(false)}>
              Aceptar
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      {forgetPassword && (
        <ModalEmailCambioPassword
          visible={forgetPassword}
          onClose={() => setForgetPassword(false)}
        />
      )}

      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          {registrarUsuario && <FormRegister onRegisterSuccess={() => setRegisterSuccess(true)} />}
          {!registrarUsuario && <FormLogin />}

          <div className={styles.footer}>
            <button type="button" className={styles.linkButton} onClick={changeForm}>
              {registrarUsuario ? 'Ya tengo un usuario' : 'Registrarme'}
            </button>
            <button
              type="button"
              className={styles.linkButton}
              onClick={() => setForgetPassword(true)}
            >
              Olvide mi contraseña
            </button>
          </div>

          <div className={styles.footerRegister}>
            <div>SAF Fuel Controller - v2.0</div>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
}

export default Login;
