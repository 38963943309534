import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Message, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import styles from './ModalAlertasTanques.module.css';

function ModalAlertasTanques({ visible, onClose }) {
  const history = useHistory();
  return (
    <Modal dimmer="blurring" open={visible} onClose={onClose} size="tiny">
      <Modal.Header className={styles.error}>Contenido minimo en tanques</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Message error icon>
            <Icon name="warning sign" />
            Atención! Tiene uno o mas tanques con contenido debajo del minimo establecido. Es
            momento de pedir combustible!
          </Message>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            history.push('/tanques');
            onClose();
          }}
        >
          Ir a tanques
        </Button>
        <Button primary onClick={onClose}>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ModalAlertasTanques.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalAlertasTanques;
