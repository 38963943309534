import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Input,
  Icon,
  Pagination,
  Table,
  Label,
  // Popup,
  Dropdown,
  Confirm,
} from 'semantic-ui-react';

import styles from './VehiculosPage.module.css';

import HeaderBar from '../../components/HeaderBar/HeaderBar';
import NuevoVehiculo from '../../components/NuevoVehiculo/NuevoVehiculo';
import ModalError from '../../components/ModalError/ModalError';
import OpcionesAvanzadas from '../../components/OpcionesAvanzadas/OpcionesAvanzadas';
import PreciosDiferenciados from '../../components/PreciosDiferenciados/PreciosDiferenciados';
import CargaSaldoVehiculo from '../../components/CargaSaldoVehiculo/CargaSaldoVehiculo';

import useToken from '../../hooks/useToken';
import useTableData from '../../hooks/useTableData';

import api from '../../api/api';

function VehiculosPage() {
  const [cargaSaldoVehiculo, setCargaSaldoVehiculo] = useState(false);
  const [precioDiferenciado, setPreciosDiferenciados] = useState(false);
  const [editarOpcionesAvanzadas, setEditarOpcionesAvanzadas] = useState(false);
  const [msgEliminar, setMsgEliminar] = useState('');
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [dominio, setDominio] = useState('');
  const [nuevoVehiculo, setNuevoVehiculo] = useState(false);
  const [editarVehiculo, setEditarVehiculo] = useState(false);
  const [vehiculoEdit, setVehiculoEdit] = useState(null);
  const [productos, setProductos] = useState([]);
  const [flotas, setFlotas] = useState([]);
  const [id, setId] = useState(-1);

  // Traer el token de autorizacion
  const { token, permiso } = useToken();

  // Traer el hook de tablas
  const { setData, setRawData, setCurrentPage, rawData, dataToShow, currentPage, totalPages } =
    useTableData();

  const fetchFlotas = () => {
    api
      .get('flotas/', { headers: { Authorization: `Token ${token}` } })
      .then((response) => {
        const flotasData = response.data;
        const flotasOptions = [];
        flotasOptions.push({ key: 0, text: 'SIN FLOTA', value: 0 });
        flotasData.forEach((f) => {
          flotasOptions.push({
            key: f.id,
            text: f.nombre,
            value: f.id,
          });
        });
        setFlotas(flotasOptions);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  const fecthProductos = () => {
    api
      .get('productos/', { headers: { Authorization: `Token ${token}` } })
      .then((response) => {
        const prodData = response.data;
        const prodOptions = [];
        prodData.forEach((p) => {
          prodOptions.push({
            key: p.id,
            text: p.nombre,
            value: p.id,
          });
        });
        setProductos(prodOptions);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  const fetchVehiculos = () => {
    api
      .get('vehiculos/', { headers: { Authorization: `Token ${token}` } })
      .then((response) => {
        console.log(response.data);
        setRawData(response.data);
        setData(response.data);
        setHasError(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
      });
  };

  const handleConfirm = () => {
    api
      .delete(`vehiculos/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        setHasError(false);
        fetchVehiculos();
        setOpen(false);
      })
      .catch((error) => {
        setHasError(true);
        console.log(error.response);
      });
  };

  const toggleHabilitacion = (v) => {
    const data = {
      id: v.id,
      habilitado: !v.habilitado,
    };

    api
      .patch(`vehiculos/${v.id}/`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        setHasError(false);
        fetchVehiculos();
      })
      .catch((error) => {
        setHasError(true);
        console.log(error.response);
      });
  };

  useEffect(() => {
    fetchVehiculos();
    fecthProductos();
    fetchFlotas();
  }, []);

  const doFilter = (filtro) => {
    if (filtro !== '') {
      const fd = rawData
        // Filtrar
        .filter(
          (d) =>
            d.dominio.toLowerCase().indexOf(filtro.toLowerCase()) > -1 ||
            (d.interno && d.interno.toString().indexOf(filtro) > -1),
        );

      // Establecer la data
      setData(fd);

      // Ir a la primer pagina
      setCurrentPage(1);
    } else {
      setData(rawData);
    }
  };

  const handleSearch = (e, { value }) => {
    setDominio(value);
    doFilter(value);
  };

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  return (
    <>
      <HeaderBar titulo="Gestión de vehiculos" />
      <div className={styles.pageContainer}>
        {open && (
          <Confirm
            open={open}
            onCancel={() => setOpen(false)}
            onConfirm={handleConfirm}
            header={msgEliminar}
            content="¿Seguro que desea eliminar el vehiculo?"
            confirmButton="Eliminar"
            cancelButton="Cancelar"
            size="tiny"
          />
        )}

        {hasError && <ModalError visible={hasError} onClose={() => setHasError(false)} />}

        {nuevoVehiculo && (
          <NuevoVehiculo
            visible={nuevoVehiculo}
            onClose={() => setNuevoVehiculo(false)}
            onEdit={fetchVehiculos}
            vehiculoEdit={null}
            productos={productos}
            flotas={flotas}
          />
        )}

        {editarVehiculo && (
          <NuevoVehiculo
            visible={editarVehiculo}
            id={id}
            onClose={() => {
              setEditarVehiculo(false);
              setVehiculoEdit(null);
            }}
            onEdit={fetchVehiculos}
            vehiculoEdit={vehiculoEdit}
            productos={productos}
            flotas={flotas}
          />
        )}

        {editarOpcionesAvanzadas && (
          <OpcionesAvanzadas
            visible={editarOpcionesAvanzadas}
            onClose={() => setEditarOpcionesAvanzadas(false)}
            vehiculoEdit={vehiculoEdit}
            onEdit={fetchVehiculos}
          />
        )}

        {precioDiferenciado && (
          <PreciosDiferenciados
            productos={productos}
            visible={precioDiferenciado}
            onClose={() => setPreciosDiferenciados(false)}
            vehiculoEdit={vehiculoEdit}
            onEdit={fetchVehiculos}
          />
        )}

        {cargaSaldoVehiculo && (
          <CargaSaldoVehiculo
            visible={cargaSaldoVehiculo}
            onClose={() => setCargaSaldoVehiculo(false)}
            vehiculoEdit={vehiculoEdit}
            onEdit={fetchVehiculos}
          />
        )}

        <div className={styles.tableContainer}>
          <Grid stackable columns={4}>
            <Grid.Row>
              <Grid.Column>
                <Input
                  fluid
                  icon="search"
                  placeholder="Buscar domino/interno..."
                  value={dominio}
                  onChange={handleSearch}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Table compact unstackable selectable verticalAlign="middle">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Dominio</Table.HeaderCell>
                <Table.HeaderCell>Interno</Table.HeaderCell>
                <Table.HeaderCell>Flota</Table.HeaderCell>
                <Table.HeaderCell>Habilitado</Table.HeaderCell>
                <Table.HeaderCell>TAG</Table.HeaderCell>
                <Table.HeaderCell>Producto Habilitado</Table.HeaderCell>
                <Table.HeaderCell>Vuelta a Cero</Table.HeaderCell>
                <Table.HeaderCell>Permite Saldo Neg.</Table.HeaderCell>
                <Table.HeaderCell>Saldo</Table.HeaderCell>
                <Table.HeaderCell>Opciones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {dataToShow.map((v) => (
                <Table.Row key={v.id}>
                  <Table.Cell collapsing>{v.id}</Table.Cell>
                  <Table.Cell collapsing>{v.dominio}</Table.Cell>
                  <Table.Cell>{v.interno}</Table.Cell>
                  <Table.Cell>{v.flota ? v.flota_nombre : ''}</Table.Cell>
                  <Table.Cell>
                    {v.habilitado ? (
                      <Label
                        color="teal"
                        onClick={() => toggleHabilitacion(v)}
                        style={{ cursor: 'pointer' }}
                      >
                        SI
                      </Label>
                    ) : (
                      <Label
                        color="red"
                        onClick={() => toggleHabilitacion(v)}
                        style={{ cursor: 'pointer' }}
                      >
                        NO
                      </Label>
                    )}
                  </Table.Cell>
                  <Table.Cell>{v.tag_uid}</Table.Cell>
                  <Table.Cell>
                    {v.multiproducto ? 'TODOS' : v.producto_habilitado_nombre}
                  </Table.Cell>
                  <Table.Cell>
                    {v.vuelta_cero ? (
                      <Icon name="check circle" color="teal" />
                    ) : (
                      <Icon name="times circle" color="red" />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {v.permite_saldo_negativo ? (
                      <Icon name="check circle" color="teal" />
                    ) : (
                      <Icon name="times circle" color="red" />
                    )}
                  </Table.Cell>

                  {(!v.permite_saldo_negativo || !v.vuelta_cero) && (
                    <Table.Cell>
                      {v.saldo > 0 ? (
                        <Label color="green" basic>
                          $ {v.saldo}
                        </Label>
                      ) : (
                        <Label color="red" basic>
                          -$ {v.saldo * -1}
                        </Label>
                      )}
                    </Table.Cell>
                  )}

                  {v.permite_saldo_negativo && v.vuelta_cero && (
                    <Table.Cell>
                      <Label color="green" basic>
                        ILIMITADO
                      </Label>
                    </Table.Cell>
                  )}

                  <Table.Cell collapsing>
                    <Dropdown text="Mas" inline floating direction="left">
                      <Dropdown.Menu>
                        <Dropdown.Item
                          disabled={permiso === 1}
                          text="Editar Vehiculo"
                          onClick={() => {
                            setEditarVehiculo(true);
                            setVehiculoEdit(v);
                          }}
                        />
                        <Dropdown.Item
                          disabled={permiso === 1}
                          text="Eliminar Vehiculo"
                          onClick={() => {
                            setMsgEliminar(
                              `Eliminar vehiculo - ${v.dominio} ${
                                v.interno !== null ? ` - Interno ${v.interno}` : ''
                              }`,
                            );
                            setOpen(true);
                            setId(v.id);
                          }}
                        />
                        <Dropdown.Divider />
                        <Dropdown.Item
                          disabled={permiso === 1}
                          text="Opciones Avanzadas"
                          onClick={() => {
                            setEditarOpcionesAvanzadas(true);
                            setVehiculoEdit(v);
                          }}
                        />
                        <Dropdown.Divider />
                        <Dropdown.Item
                          disabled={permiso === 1}
                          text="Precios diferenciados"
                          onClick={() => {
                            setPreciosDiferenciados(true);
                            setVehiculoEdit(v);
                          }}
                        />
                        <Dropdown.Item
                          text="Cargar saldo"
                          onClick={() => {
                            setCargaSaldoVehiculo(true);
                            setVehiculoEdit(v);
                          }}
                        />
                      </Dropdown.Menu>
                    </Dropdown>

                    {/* <Popup
                      content="Editar vehiculo"
                      trigger={
                        <Button
                          circular
                          icon="edit outline"
                          size="tiny"
                          onClick={() => {
                            setEditarVehiculo(true);
                            setVehiculoEdit(v);
                          }}
                        />
                      }
                    />

                    <Popup
                      content="Opciones avanzadas"
                      trigger={
                        <Button
                          circular
                          icon="cogs"
                          size="tiny"
                          onClick={() => {
                            setEditarOpcionesAvanzadas(true);
                            setVehiculoEdit(v);
                          }}
                        />
                      }
                    />

                    <Popup
                      content="Precio diferenciado"
                      trigger={
                        <Button
                          circular
                          icon="dollar"
                          size="tiny"
                          onClick={() => {
                            setPreciosDiferenciados(true);
                            setVehiculoEdit(v);
                          }}
                        />
                      }
                    />

                    <Popup
                      content="Eliminar vehiculo"
                      trigger={
                        <Button
                          circular
                          icon="trash"
                          size="tiny"
                          onClick={() => {
                            setMsgEliminar(
                              `Eliminar vehiculo - ${v.dominio} ${
                                v.interno !== null ? ` - Interno ${v.interno}` : ''
                              }`,
                            );
                            setOpen(true);
                            setId(v.id);
                          }}
                        />
                      }
                    /> */}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="11">
                  <div className={styles.tableFooter}>
                    <Button
                      disabled={permiso === 1}
                      floated="left"
                      icon
                      labelPosition="left"
                      primary
                      size="small"
                      onClick={() => setNuevoVehiculo(true)}
                    >
                      <Icon name="user add" />
                      Nuevo Vehiculo
                    </Button>
                    {totalPages > 1 && (
                      <Pagination
                        activePage={currentPage}
                        boundaryRange={1}
                        size="mini"
                        siblingRange={1}
                        totalPages={totalPages}
                        onPageChange={handlePaginationChange}
                      />
                    )}
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </div>
    </>
  );
}

export default VehiculosPage;
