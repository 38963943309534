import React, { useState, useContext, useEffect } from 'react';
import {
  Icon,
  Label,
  Table,
  Grid,
  Form,
  Button,
  Modal,
  Message,
  Pagination,
  Header,
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import { useMediaQuery } from 'react-responsive';
import { formatInTimeZone } from 'date-fns-tz';
import { parse } from 'date-fns';

import styles from './TurnosPage.module.css';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import ModalError from '../../components/ModalError/ModalError';
import VisualizarCierre from '../../components/VisualizarCierre/VisualizarCierre';

import useToken from '../../hooks/useToken';
import useTableData from '../../hooks/useTableData';

import { SedeContext } from '../../context/SedeContext';

import api from '../../api/api';

function TurnosPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCierre, setIsLoadingCierre] = useState(false);
  const [ultimoTurno, setUltimoTurno] = useState([]);
  const [ultimoTurnoStr, setUltimoTurnoStr] = useState('');
  const [modal, setModal] = useState(false);
  const [modalSinDatos, setModalSinDatos] = useState(false);
  const [visualizarCierre, setVisualizarCierre] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [fechaDesde, setFechaDesde] = useState('');
  const [fechaHasta, setFechaHasta] = useState('');
  const [turnoSeleccionado, setTurnoSeleccionado] = useState([]);

  const { token } = useToken();
  const { sede } = useContext(SedeContext);

  // Traer el hook de tablas
  const {
    setData,
    setRawData,
    setCurrentPage,
    dataToShow,
    currentPage,
    totalRegisters,
    totalPages,
  } = useTableData();

  const isLaptop = useMediaQuery({ query: '(max-width: 1280px)' });

  const handleChange = (e, { name, value }) => {
    if (name === 'desde') {
      setFechaDesde(value);
    } else {
      setFechaHasta(value);
    }
  };

  const fetchTurnoAbierto = () => {
    api
      .get(`turnos/actual/`, {
        headers: { Authorization: `Token ${token}` },
        params: { sede: sede.codigo },
      })
      .then((response) => {
        let ultimoTurno = response.data[0];

        let fecha = formatInTimeZone(new Date(ultimoTurno.abierto), 'UTC', 'dd/MM/yyyy HH:mm:ss');
        setUltimoTurno(ultimoTurno);
        setUltimoTurnoStr('Turno actual: ' + ultimoTurno.id + ' - ' + fecha);
      });
  };

  useEffect(() => {
    fetchTurnoAbierto();
  }, []);

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  // const mensajeCierre = () => {
  //   setIsLoading(false);
  //   setModal(false);
  // };

  const handleConsulta = () => {
    let fdesde;
    let fhasta;

    let params;

    if (fechaDesde !== '' && fechaHasta !== '') {
      const fd = parse(fechaDesde, 'dd-MM-yyyy', new Date());
      const fh = parse(fechaHasta, 'dd-MM-yyyy', new Date());

      // Convertir a ISO sin tener en cuenta la TZ
      const fdtz = new Date(fd.getTime() - fd.getTimezoneOffset() * 60 * 1000);
      const fhtz = new Date(fh.getTime() - fh.getTimezoneOffset() * 60 * 1000);

      fdesde = fdtz.toISOString();
      fhasta = fhtz.toISOString();

      params = {
        desde: fdesde,
        hasta: fhasta,
        sede: sede.codigo,
      };
    } else {
      fdesde = '';
      fhasta = '';

      params = {
        sede: sede.codigo,
      };
    }

    setIsLoading(true);

    api
      .get(`turnos/`, {
        headers: { Authorization: `Token ${token}` },
        params: params,
      })
      .then((response) => {
        setModalSinDatos(response.data.length === 0);
        setRawData(response.data);
        setData(response.data);
        setHasError(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCierre = () => {
    setIsLoadingCierre(true);
    api
      .get(`turnos/cerrar/${ultimoTurno.id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        setUltimoTurno(response.data);
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setModal(false);
        setIsLoadingCierre(false);
      });
  };

  return (
    <>
      <ModalError visible={hasError} onClose={() => setHasError(false)} />

      {modalSinDatos && (
        <Modal dimmer="blurring" open={modalSinDatos} size="tiny">
          <Modal.Header>Cierre de turno</Modal.Header>
          <Modal.Content>
            No existe ningun turno cerrado entre las fechas seleccionadas
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={() => setModalSinDatos(false)}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      {modal && (
        <Modal dimmer="blurring" open={modal} size="tiny">
          <Modal.Header>Cierre de turno</Modal.Header>
          <Modal.Content>
            <Message>
              {`¿Esta seguro que desea cerrar el turno ${ultimoTurno.id} abierto el dia
                  ${formatInTimeZone(
                    new Date(ultimoTurno.abierto),
                    'UTC',
                    'dd/MM/yyyy HH:mm:ss',
                  )}?`}
            </Message>
            <Modal.Description>
              <Message icon warning>
                <Icon name="warning sign" />
                Por favor, asegurese que todos los picos se encuentren colgados antes de realizar el
                cierre
              </Message>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setModal(false)}>Cancelar</Button>
            <Button primary onClick={handleCierre} loading={isLoadingCierre}>
              Realizar cierre
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      {visualizarCierre && (
        <VisualizarCierre
          visible={visualizarCierre}
          onClose={() => setVisualizarCierre(false)}
          turnoSeleccionado={turnoSeleccionado}
        />
      )}

      <HeaderBar
        titulo="Historial de turnos"
        subtitulo="Oprima consultar para obtener turnos cerrados entre las fechas seleccionadas"
      />
      <div className={styles.pageContainer}>
        <div className={styles.tableContainer}>
          <Header as="h4">{ultimoTurnoStr}</Header>
          <Form autoComplete="off" noValidate onSubmit={handleConsulta}>
            <Grid stackable columns={6}>
              <Grid.Row>
                <Grid.Column>
                  <DateInput
                    autoComplete="off"
                    closable
                    clearable
                    clearIcon={<Icon name="remove" color="red" />}
                    name="desde"
                    placeholder="Desde"
                    onChange={handleChange}
                    value={fechaDesde}
                  />
                </Grid.Column>
                <Grid.Column>
                  <DateInput
                    autoComplete="off"
                    closable
                    clearable
                    clearIcon={<Icon name="remove" color="red" />}
                    name="hasta"
                    placeholder="Hasta"
                    onChange={handleChange}
                    value={fechaHasta}
                    minDate={fechaDesde}
                  />
                </Grid.Column>

                <Grid.Column>
                  <Button primary icon labelPosition="left" loading={isLoading}>
                    <Icon name="search" />
                    Consultar
                  </Button>
                </Grid.Column>

                <Grid.Column width={isLaptop ? 4 : 3} floated="right" stretched>
                  <Button
                    type="button"
                    color="teal"
                    icon
                    fluid
                    labelPosition="left"
                    onClick={() => setModal(true)}
                  >
                    <Icon name="clock" />
                    Cerrar Turno Actual
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>

          <Table unstackable selectable verticalAlign="middle">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Fecha Abierto</Table.HeaderCell>
                <Table.HeaderCell>Fecha Cerrado</Table.HeaderCell>
                <Table.HeaderCell>Cerrado por</Table.HeaderCell>
                <Table.HeaderCell>Opciones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {dataToShow.map((turno) => (
                <Table.Row key={turno.id}>
                  <Table.Cell collapsing>{turno.id}</Table.Cell>
                  <Table.Cell>
                    {formatInTimeZone(new Date(turno.abierto), 'UTC', 'dd/MM/yyyy HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>
                    {formatInTimeZone(new Date(turno.cerrado), 'UTC', 'dd/MM/yyyy HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>{turno.username}</Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      icon
                      size="tiny"
                      primary
                      onClick={() => {
                        setTurnoSeleccionado(turno);
                        setVisualizarCierre(true);
                      }}
                    >
                      <Icon name="eye" />
                      Ver
                    </Button>
                    <Button icon size="tiny">
                      <Icon name="file pdf outline" />
                      Descargar reporte
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <div className={styles.tableFooter}>
                    <Label>Cantidad de registros: {totalRegisters}</Label>
                    {totalPages > 1 && (
                      <Pagination
                        activePage={currentPage}
                        boundaryRange={1}
                        size="mini"
                        siblingRange={1}
                        totalPages={totalPages}
                        onPageChange={handlePaginationChange}
                      />
                    )}
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </div>
    </>
  );
}

export default TurnosPage;
