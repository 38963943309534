import React, { useState, useContext } from 'react';
import { Icon, Pagination, Table, Grid, Form, Button, Label, Ref } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import { formatInTimeZone } from 'date-fns-tz';
import ReactExport from 'react-export-excel';

import styles from './DesbloqueosPage.module.css';

import HeaderBar from '../../components/HeaderBar/HeaderBar';
import ModalError from '../../components/ModalError/ModalError';

import { SedeContext } from '../../context/SedeContext';

import useToken from '../../hooks/useToken';
import useTableData from '../../hooks/useTableData';

import api from '../../api/api';

function DesbloqueosPage() {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fechaDesde, setFechaDesde] = useState('');
  const [fechaHasta, setFechaHasta] = useState('');
  const { sede } = useContext(SedeContext);

  const buttonRef = React.useRef(null);

  const { ExcelFile } = ReactExport;
  const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

  // Traer el token de autenticacion
  const { token } = useToken();

  // Traer el hook de tablas
  const {
    setData,
    setRawData,
    setCurrentPage,
    dataToShow,
    data,
    currentPage,
    totalRegisters,
    totalPages,
  } = useTableData();

  const handleDescarga = () => {
    if (buttonRef.current !== null) {
      buttonRef.current.click();
    }
  };

  const handleChange = (e, { name, value }) => {
    if (name === 'desde') {
      setFechaDesde(value);
    } else {
      setFechaHasta(value);
    }
  };

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handleConsulta = () => {
    let fdesde;
    let fhasta;

    if (fechaDesde !== undefined && fechaHasta !== undefined) {
      fdesde = fechaDesde.split('-').reverse().join('-');
      fhasta = fechaHasta.split('-').reverse().join('-');
    } else {
      fdesde = '';
      fhasta = '';
    }

    const params = {
      fechaDesde: fdesde,
      fechaHasta: fhasta,
    };

    setIsLoading(true);

    api
      .get(`sede/${sede.codigo}/desbloqueos/`, {
        headers: { Authorization: `Token ${token}` },
        params: params,
      })
      .then((response) => {
        setRawData(response.data);
        setData(response.data);
        setHasError(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <HeaderBar titulo="Desbloqueos de precintos" />
      <div className={styles.pageContainer}>
        <ModalError visible={hasError} onClose={() => setHasError(false)} />

        <div className={styles.tableContainer}>
          <Form autoComplete="off">
            <Grid stackable columns={6}>
              <div className={styles.subtitle}>Filtros</div>
              <Grid.Row>
                <Grid.Column>
                  <DateInput
                    autoComplete="off"
                    closable
                    clearable
                    clearIcon={<Icon name="remove" color="red" />}
                    name="desde"
                    placeholder="Desde"
                    onChange={handleChange}
                    value={fechaDesde}
                  />
                </Grid.Column>
                <Grid.Column>
                  <DateInput
                    autoComplete="off"
                    closable
                    clearable
                    clearIcon={<Icon name="remove" color="red" />}
                    name="hasta"
                    placeholder="Hasta"
                    onChange={handleChange}
                    value={fechaHasta}
                    minDate={fechaDesde}
                  />
                </Grid.Column>

                <Grid.Column>
                  <Button
                    primary
                    icon
                    labelPosition="left"
                    loading={isLoading}
                    onClick={handleConsulta}
                  >
                    <Icon name="search" />
                    Consultar
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>

          <Table unstackable selectable verticalAlign="middle">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Fecha</Table.HeaderCell>
                <Table.HeaderCell>Dominio</Table.HeaderCell>
                <Table.HeaderCell>Interno</Table.HeaderCell>
                <Table.HeaderCell>Precinto 1 Valido</Table.HeaderCell>
                <Table.HeaderCell>Precinto 2 Valido</Table.HeaderCell>
                <Table.HeaderCell>Precinto 1 Utilizado</Table.HeaderCell>
                <Table.HeaderCell>Precinto 2 Utilizado</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {dataToShow.map((d) => (
                <Table.Row key={d.id}>
                  <Table.Cell collapsing>{d.id.replace(/[^0-9]/g, '')}</Table.Cell>
                  <Table.Cell collapsing>
                    {formatInTimeZone(new Date(d.fecha), 'UTC', 'dd/MM/yyyy HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>{d.dominio ? d.dominio : ''}</Table.Cell>
                  <Table.Cell>{d.interno ? d.interno : ''}</Table.Cell>
                  <Table.Cell>{d.precinto1anterior}</Table.Cell>
                  <Table.Cell>{d.precinto2anterior}</Table.Cell>
                  <Table.Cell>{d.precinto1actual}</Table.Cell>
                  <Table.Cell>{d.precinto2actual}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <div className={styles.tableFooter}>
                    <Label>Cantidad de registros: {totalRegisters}</Label>
                    {totalPages > 1 && (
                      <Pagination
                        activePage={currentPage}
                        boundaryRange={1}
                        size="mini"
                        siblingRange={1}
                        totalPages={totalPages}
                        onPageChange={handlePaginationChange}
                      />
                    )}
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <Button floated="right" color="teal" icon labelPosition="left" onClick={handleDescarga}>
            <Icon name="download" />
            Descargar XLS
          </Button>

          <ExcelFile
            filename="Movimientos"
            element={
              <Ref innerRef={buttonRef}>
                <button type="button" hidden>
                  hidden
                </button>
              </Ref>
            }
          >
            <ExcelSheet data={data} name="Desbloqueos">
              <ExcelColumn label="ID" value="id" />
              <ExcelColumn
                label="Fecha"
                value={(col) => formatInTimeZone(new Date(col.fecha), 'UTC', 'dd/MM/yyyy HH:mm:ss')}
              />
              <ExcelColumn label="Dominio" value="dominio" />
              <ExcelColumn label="Interno" value="interno" />
              <ExcelColumn label="Precinto 1 Valido" value="precinto1anterior" />
              <ExcelColumn label="Precinto 2 Valido" value="precinto2anterior" />
              <ExcelColumn label="Precinto 1 Utilizado" value="precinto1actual" />
              <ExcelColumn label="Precinto 2 Utilizado" value="precinto2actual" />
            </ExcelSheet>
          </ExcelFile>
        </div>
      </div>
    </>
  );
}

export default DesbloqueosPage;
