import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';

import useToken from '../../hooks/useToken';
import api from '../../api/api';

function EditarPlayero({ visible, onClose, playero, onEdit }) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit, setValue } = useForm();

  const { token } = useToken();

  // Si no hay id, establecer el flag indicando que es un nuevo vehiculo
  const isNuevoPlayero = !playero;

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const submitForm = (data) => {
    setIsLoading(true);

    if (isNuevoPlayero) {
      api
        .post(`/playeros/`, data, {
          headers: { Authorization: `Token ${token}` },
        })
        .then(() => {
          onEdit(data);
          setHasError(false);
          cerrarFormulario();
        })
        .catch((error) => {
          setHasError(true);

          if (error.response.status === 409) {
            setErrorMessage(error.response.data);
          } else {
            setErrorMessage('Error al comunicarse con el servidor');
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      api
        .patch(`/playeros/${playero.id}/`, data, {
          headers: { Authorization: `Token ${token}` },
        })
        .then(() => {
          onEdit(data);
          setHasError(false);
          cerrarFormulario();
        })
        .catch(() => {
          setHasError(true);
        })
        .finally(() => setIsLoading(false));
    }
  };

  // Si se esta editando, prepopular el form
  useEffect(() => {
    if (!isNuevoPlayero) {
      setValue('nombre', playero.nombre);
      setValue('tag_uid', playero.tag_uid);
    }
  });

  return (
    <Modal dimmer="blurring" open={visible} size="tiny">
      <Modal.Header>
        {isNuevoPlayero ? 'Nuevo playero' : `Editar Playero - #${playero.id}`}
      </Modal.Header>
      <Modal.Content>
        <Form
          loading={isLoading}
          onSubmit={handleSubmit(submitForm)}
          id="formEditarPlayero"
          noValidate
          error={hasError}
        >
          <Form.Group widths="equal">
            <FormInput
              required
              fluid
              label="Nombre"
              placeholder="Nombre"
              control={control}
              validationRules={{ pattern: /^[a-zA-Z0-9 ]*$/ }}
            />
            <FormInput required name="tag_uid" label="TAG" placeholder="TAG" control={control} />
          </Form.Group>
          <Message error header="Error" content={errorMessage} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formEditarPlayero" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

EditarPlayero.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  playero: PropTypes.object.isRequired,
};

export default EditarPlayero;
