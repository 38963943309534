import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormSelect from '../FormSelect/FormSelect';

import useToken from '../../hooks/useToken';

// import { SedeContext } from '../../context/SedeContext';

import api from '../../api/api';

const modoOptions = [
  { key: 0, text: 'Automatica', value: 0 },
  { key: 1, text: 'Flotas', value: 1 },
];

function ModoAutorizacion({ visible, onClose, surtidor }) {
  const [msg, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Traer la sede
  // const { sede } = useContext(SedeContext);

  // Traer el token de autorizacion
  const { token } = useToken();

  const { control, handleSubmit, setValue } = useForm();

  useEffect(() => {
    setValue('modo_autorizacion', surtidor.modo_autorizacion);
  }, []);

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const submitForm = (data) => {
    setIsLoading(true);

    api
      .patch(`surtidores/${surtidor.id}/`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        setHasError(false);
        cerrarFormulario();
      })
      .catch(() => {
        setHasError(true);
        setErrorMessage('Error al comunicarse con el servidor');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal dimmer="blurring" open={visible} size="mini">
      <Modal.Header>{`Modo autorizacion surtidor ${
        surtidor === null ? '' : surtidor.display_id
      }`}</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={handleSubmit(submitForm)}
          id="formCambiarModoAutorizacion"
          noValidate
          loading={isLoading}
        >
          <Form.Group widths="equal">
            <FormSelect
              required
              name="modo_autorizacion"
              fluid
              label="Modo"
              control={control}
              options={modoOptions}
            />
          </Form.Group>
        </Form>
        <Message hidden={!hasError} error header="Error" content={msg} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formCambiarModoAutorizacion" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ModoAutorizacion.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  surtidor: PropTypes.object.isRequired,
};

export default ModoAutorizacion;
