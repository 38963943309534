import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';
import FormCheck from '../FormCheck/FormCheck';

import useToken from '../../hooks/useToken';
import api from '../../api/api';

function NuevaFlota({ visible, onClose, flota, onEdit }) {
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginHabilitado, setLoginHabilitado] = useState(false);

  const { control, handleSubmit, setValue } = useForm();
  const { token } = useToken();

  // Si no hay id, establecer el flag indicando que es un nuevo vehiculo
  const isNuevaFlota = !flota;

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const submitForm = (data) => {
    setIsLoading(true);

    if (isNuevaFlota) {
      api
        .post(`flotas/`, data, {
          headers: { Authorization: `Token ${token}` },
        })
        .then(() => {
          onEdit(data);
          setHasError(false);
          setErrorMsg('');
          cerrarFormulario();
        })
        .catch((e) => {
          setHasError(true);
          if (e.response.status === 409) {
            setErrorMsg(e.response.data);
          } else {
            setErrorMsg('Error al intentar crear una nueva flota de vehiculos');
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      api
        .patch(`flotas/${flota.id}/`, data, {
          headers: { Authorization: `Token ${token}` },
        })
        .then(() => {
          onEdit(data);
          setHasError(false);
          cerrarFormulario();
        })
        .catch(() => {
          setHasError(true);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleLoginHabilitadoChange = (v) => {
    setLoginHabilitado(v);
  };

  // Si se esta editando, prepopular el form
  useEffect(() => {
    if (!isNuevaFlota) {
      setValue('email', flota.email);
      setValue('habilitado', flota.habilitado);
      setValue('login_habilitado', flota.login_habilitado);
      setLoginHabilitado(flota.login_habilitado);
    }
  });

  return (
    <Modal dimmer="blurring" open={visible} size="tiny">
      <Modal.Header>{isNuevaFlota ? 'Nueva Flota' : `Editar Flota - #${flota.id}`}</Modal.Header>
      <Modal.Content>
        <Form
          loading={isLoading}
          onSubmit={handleSubmit(submitForm)}
          id="formEditarFlota"
          noValidate
          error={hasError}
        >
          <FormInput
            required
            fluid
            label="Nombre"
            placeholder="Nombre"
            control={control}
            validationRules={{ pattern: /^[a-zA-Z0-9 ]*$/ }}
          />

          <FormCheck
            name="login_habilitado"
            label="Acceso terceros habilitado"
            control={control}
            onSelectionChanged={handleLoginHabilitadoChange}
          />

          <Message icon info hidden={!loginHabilitado}>
            Ingresar un correo electronico para permitir el acceso de terceros a la gestion de
            flotas. El usuario ingresado deberá verificar su cuenta y crear sus credenciales de
            inicio de sesión mediante el correo que se le enviará automaticamente
          </Message>

          <FormInput
            disabled={!loginHabilitado || !isNuevaFlota}
            fluid
            label="Email"
            placeholder="usuario@flotas.com"
            name="email"
            control={control}
            validationRules={{ pattern: /^.+@.+\..+$/ }}
          />

          <Message error header="Error" content={errorMsg} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formEditarFlota" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

NuevaFlota.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  flota: PropTypes.object.isRequired,
};

export default NuevaFlota;
