import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Header, Popup, Message, Icon } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';

import useToken from '../../hooks/useToken';
import api from '../../api/api';

function ModalPerfil({ visible, onClose }) {
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [perfilActualizado, setPerfilActualizado] = useState(false);
  const [patch, setPatch] = useState(false);
  const { token } = useToken();

  const { control, handleSubmit, setValue } = useForm();

  const submitForm = (data) => {
    setIsLoading(true);
    setError(false);

    let perfilData = {
      nombre: data.nombre,
      apellido: data.apellido,
      email: data.email,
    };

    let perfilExtData = {
      emails_adicionales: data.emails_adicionales,
      telefono: data.telefono,
    };

    /* Si hubo datos extentidos, patchear */
    if (patch)
      api
        .patch('perfilext/', perfilExtData, { headers: { Authorization: `Token ${token}` } })
        .catch((e) => {
          setError(true);
          setMsgError(e.response.data);
        });
    else {
      /* Si no hubo datos extendidos, y ahora los hay en el formulario, postear */
      if (data.emails_adicionales || data.telefono)
        api
          .post('perfilext/', perfilExtData, { headers: { Authorization: `Token ${token}` } })
          .catch((e) => {
            setError(true);
            setMsgError(e.response.data);
          });
    }

    /* Patchear el perfil con los datos basicos */
    api
      .patch('perfil/', perfilData, { headers: { Authorization: `Token ${token}` } })
      .then(() => {
        setPerfilActualizado(true);
        setTimeout(onClose, 1500);
      })
      .catch((e) => {
        setError(true);
        setMsgError(e.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchPerfil = () => {
    api.get('perfil/', { headers: { Authorization: `Token ${token}` } }).then((r) => {
      setValue('nombre', r.data.nombre);
      setValue('email', r.data.email);
      setValue('apellido', r.data.apellido);
    });
  };

  const fetchPerfilExt = () => {
    api
      .get('perfilext/', { headers: { Authorization: `Token ${token}` } })
      .then((r) => {
        setValue('emails_adicionales', r.data.emails_adicionales);
        setValue('telefono', r.data.telefono);
        setPatch(true);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setPatch(false);
        } else {
          setPatch(true);
        }
      });
  };

  useEffect(() => {
    fetchPerfil();
    fetchPerfilExt();
  }, []);

  return (
    <Modal dimmer="blurring" open={visible} onClose={onClose} size="tiny">
      <Modal.Header>Mi Perfil</Modal.Header>
      <Modal.Content>
        <Form loading={isLoading} id="formPerfil" onSubmit={handleSubmit(submitForm)}>
          <Header as="h4">Mis datos</Header>
          <Form.Group widths="equal">
            <FormInput name="nombre" label="Nombre" placeholder="Nombre" fluid control={control} />
            <FormInput
              name="apellido"
              label="Apellido"
              placeholder="Apellido"
              fluid
              control={control}
            />
          </Form.Group>

          <Header as="h4">Informacion de contacto</Header>

          <Form.Group widths="equal">
            <FormInput
              name="email"
              label="Correo Electronico"
              placeholder="info@info.com"
              fluid
              control={control}
            />
            <FormInput
              name="telefono"
              label="Numero de telefono"
              placeholder="1122224444"
              fluid
              control={control}
            />
          </Form.Group>
          <Popup
            trigger={
              <FormInput
                name="emails_adicionales"
                label="Email adicionales para alertas"
                placeholder="info@info.com"
                fluid
                control={control}
                validationRules={{
                  pattern:
                    /^(\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4}\s*?,?\s*?)+$/,
                }}
              />
            }
            popper={<div style={{ filter: 'none' }}></div>}
            content="Podes agregar multiples correos electronicos separados por comas"
          />
        </Form>
        {perfilActualizado && (
          <Message icon color="teal">
            <Icon name="check circle outline" />
            Perfil actualizado correctamente!
          </Message>
        )}

        {error && (
          <Message icon error>
            <Icon name="times circle outline" />
            Error al actualizar el perfil. {msgError}
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button form="formPerfil" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ModalPerfil.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalPerfil;
