import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const SedeContext = createContext(1);

export const SedeProvider = ({ children }) => {
  SedeProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const [sede, setSede] = useState({
    id: 1,
    codigo: 'A',
    nombre: null,
  });

  const [listaSedes, setListaSedes] = useState([]);

  return (
    <SedeContext.Provider value={{ sede, setSede, listaSedes, setListaSedes }}>
      {children}
    </SedeContext.Provider>
  );
};
