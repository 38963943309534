import React, { useEffect, useState, useContext } from 'react';
import {
  Icon,
  Pagination,
  Table,
  Grid,
  Form,
  Button,
  Dropdown,
  Label,
  Ref,
  Modal,
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import { formatInTimeZone } from 'date-fns-tz';
import { parse } from 'date-fns';
import ReactExport from 'react-export-excel';

import styles from './MovimientosPage.module.css';

import HeaderBar from '../../components/HeaderBar/HeaderBar';
import ModalError from '../../components/ModalError/ModalError';
import DetalleMovimiento from '../../components/DetalleMovimiento/DetalleMovimiento';

import { SedeContext } from '../../context/SedeContext';

import useToken from '../../hooks/useToken';
import useTableData from '../../hooks/useTableData';

import api from '../../api/api';

function MovimientosPage() {
  const [hasError, setHasError] = useState(false);
  const [modalSinDatos, setModalSinDatos] = useState(false);
  const [verDetalles, setVerDetalles] = useState(false);
  const [tanquesOptions, setTanquesOptions] = useState([]);
  const [tanquesValue, setTanquesValue] = useState([]);
  const [detalleMovimiento, setDetallesMovimiento] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fechaDesde, setFechaDesde] = useState('');
  const [fechaHasta, setFechaHasta] = useState('');
  const { sede } = useContext(SedeContext);

  const buttonRef = React.useRef(null);

  const { ExcelFile } = ReactExport;
  const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

  // Traer el token de autenticacion
  const { token } = useToken();

  // Traer el hook de tablas
  const {
    setData,
    setRawData,
    setCurrentPage,
    dataToShow,
    data,
    currentPage,
    totalRegisters,
    totalPages,
  } = useTableData();

  const fetchTanques = () =>
    api
      .get(`/tanques/`, {
        headers: { Authorization: `Token ${token}` },
        params: { sede: sede.codigo },
      })
      .then((response) => {
        const tkData = response.data;
        const tkOptions = [];
        tkData.forEach((tk) => {
          tkOptions.push({ key: tk.id, text: `Tanque ${tk.display_id}`, value: tk.display_id });
        });
        setTanquesOptions(tkOptions);
      });

  useEffect(() => {
    // Traer los tanques y armar el array de opciones
    fetchTanques();

    // Borrar los datos de consulta
    setRawData([]);
    setData([]);
  }, [sede]);

  const handleDescarga = () => {
    if (buttonRef.current !== null) {
      buttonRef.current.click();
    }
  };

  const handleChange = (e, { name, value }) => {
    if (name === 'desde') {
      setFechaDesde(value);
    } else {
      setFechaHasta(value);
    }
  };

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handleConsulta = () => {
    let fdesde;
    let fhasta;
    let params;

    let tkValue = -1;

    if (tanquesValue.length !== 0) {
      tkValue = tanquesValue.join(',');
    }

    if (fechaDesde !== '' && fechaHasta !== '') {
      const fd = parse(fechaDesde, 'dd-MM-yyyy', new Date());
      const fh = parse(fechaHasta, 'dd-MM-yyyy', new Date());

      // Convertir a ISO sin tener en cuenta la TZ
      const fdtz = new Date(fd.getTime() - fd.getTimezoneOffset() * 60 * 1000);
      const fhtz = new Date(fh.getTime() - fh.getTimezoneOffset() * 60 * 1000);

      fdesde = fdtz.toISOString();
      fhasta = fhtz.toISOString();

      params = {
        desde: fdesde,
        hasta: fhasta,
        tanques: tkValue,
        sede: sede.codigo,
      };
    } else {
      params = {
        tanques: tkValue,
        sede: sede.codigo,
      };
    }

    setIsLoading(true);

    api
      .get(`tanques/movimientos/`, {
        headers: { Authorization: `Token ${token}` },
        params: params,
      })
      .then((response) => {
        setModalSinDatos(response.data.length === 0);
        setRawData(response.data);
        setData(response.data);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <HeaderBar titulo="Historial de movimientos en tanque" />
      <div className={styles.pageContainer}>
        <ModalError visible={hasError} onClose={() => setHasError(false)} />

        {modalSinDatos && (
          <Modal dimmer="blurring" open={modalSinDatos} size="tiny">
            <Modal.Header>Movimientos</Modal.Header>
            <Modal.Content>
              No existe ningun movimiento entre las fechas seleccionadas
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={() => setModalSinDatos(false)}>
                OK
              </Button>
            </Modal.Actions>
          </Modal>
        )}

        {verDetalles && (
          <DetalleMovimiento
            visible={verDetalles}
            onClose={() => setVerDetalles(false)}
            movimiento={detalleMovimiento}
          />
        )}

        <div className={styles.tableContainer}>
          <Form autoComplete="off">
            <Grid stackable columns={6}>
              <div className={styles.subtitle}>Filtros</div>
              <Grid.Row>
                <Grid.Column>
                  <DateInput
                    autoComplete="off"
                    closable
                    clearable
                    clearIcon={<Icon name="remove" color="red" />}
                    name="desde"
                    placeholder="Desde"
                    onChange={handleChange}
                    value={fechaDesde}
                  />
                </Grid.Column>
                <Grid.Column>
                  <DateInput
                    autoComplete="off"
                    closable
                    clearable
                    clearIcon={<Icon name="remove" color="red" />}
                    name="hasta"
                    placeholder="Hasta"
                    onChange={handleChange}
                    value={fechaHasta}
                    minDate={fechaDesde}
                  />
                </Grid.Column>

                <Grid.Column width={5}>
                  <Dropdown
                    placeholder="Tanque"
                    fluid
                    multiple
                    selection
                    options={tanquesOptions}
                    value={tanquesValue}
                    onChange={(e, { value }) => setTanquesValue(value)}
                  />
                </Grid.Column>

                <Grid.Column>
                  <Button
                    primary
                    icon
                    labelPosition="left"
                    loading={isLoading}
                    onClick={handleConsulta}
                  >
                    <Icon name="search" />
                    Consultar
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>

          <Table unstackable selectable verticalAlign="middle">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Fecha</Table.HeaderCell>
                <Table.HeaderCell>Tanque</Table.HeaderCell>
                <Table.HeaderCell>Cont. Inicial</Table.HeaderCell>
                <Table.HeaderCell>Cont. Final</Table.HeaderCell>
                <Table.HeaderCell>Volumen Operacion</Table.HeaderCell>
                <Table.HeaderCell>Tipo</Table.HeaderCell>
                <Table.HeaderCell>Detalles</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {dataToShow.map((mov) => (
                <Table.Row key={mov.id}>
                  <Table.Cell collapsing>{mov.id.replace(/[^0-9]/g, '')}</Table.Cell>
                  <Table.Cell collapsing>
                    {formatInTimeZone(new Date(mov.fecha), 'UTC', 'dd/MM/yyyy HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>{mov.tanque_display_id}</Table.Cell>
                  <Table.Cell>{mov.contenido_inicial}</Table.Cell>
                  <Table.Cell>{mov.contenido_final}</Table.Cell>
                  <Table.Cell>
                    {mov.tipo === 'CARGA' && (
                      <div className={styles.carga}>
                        <Icon name="arrow up" />
                        {mov.movimiento} Lts
                      </div>
                    )}
                    {mov.tipo === 'DESCARGA' && (
                      <div className={styles.descarga}>
                        <Icon name="arrow down" />
                        {mov.movimiento} Lts
                      </div>
                    )}
                    {mov.tipo === 'AJUSTE' && (
                      <div className={styles.ajuste}>
                        <Icon name="arrow right" />
                        {mov.movimiento} Lts
                      </div>
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {mov.tipo === 'CARGA' && (
                      <Label as="a" color="green">
                        CARGA
                      </Label>
                    )}
                    {mov.tipo === 'DESCARGA' && (
                      <Label as="a" color="red">
                        DESCARGA
                      </Label>
                    )}
                    {mov.tipo === 'AJUSTE' && (
                      <Label as="a" color="grey">
                        AJUSTE
                      </Label>
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      circular
                      icon="eye"
                      size="tiny"
                      onClick={() => {
                        setVerDetalles(true);
                        setDetallesMovimiento(mov);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <div className={styles.tableFooter}>
                    <Label>Cantidad de registros: {totalRegisters}</Label>
                    {totalPages > 1 && (
                      <Pagination
                        activePage={currentPage}
                        boundaryRange={1}
                        size="mini"
                        siblingRange={1}
                        totalPages={totalPages}
                        onPageChange={handlePaginationChange}
                      />
                    )}
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <Button floated="right" color="teal" icon labelPosition="left" onClick={handleDescarga}>
            <Icon name="download" />
            Descargar XLS
          </Button>

          <ExcelFile
            filename="Movimientos"
            element={
              <Ref innerRef={buttonRef}>
                <button type="button" hidden>
                  hidden
                </button>
              </Ref>
            }
          >
            <ExcelSheet data={data} name="Movimientos Tanque">
              <ExcelColumn label="ID" value="id" />
              <ExcelColumn label="Tanque" value="tanque_display_id" />
              <ExcelColumn
                label="Fecha"
                value={(col) => formatInTimeZone(new Date(col.fecha), 'UTC', 'dd/MM/yyyy HH:mm:ss')}
              />
              <ExcelColumn label="Contenido inicial" value="contenido_inicial" />
              <ExcelColumn label="Contenido final" value="contenido_final" />
              <ExcelColumn label="Volumen operacion" value="movimiento" />
              <ExcelColumn label="Tipo operacion" value="tipo" />
              <ExcelColumn label="Detalle" value="detalle" />
            </ExcelSheet>
          </ExcelFile>
        </div>
      </div>
    </>
  );
}

export default MovimientosPage;
