import React, { useEffect, useState } from 'react';
import { Pagination, Table, Input, Grid, Form, Label } from 'semantic-ui-react';
import { formatInTimeZone } from 'date-fns-tz';
// import ReactExport from 'react-export-excel';

import styles from './CargasVehiculosPage.module.css';
import HeaderBar from '../../components/HeaderBar/HeaderBar';

import ModalError from '../../components/ModalError/ModalError';

import useToken from '../../hooks/useToken';
import useTableData from '../../hooks/useTableData';

import api from '../../api/api';

function CargasVehiculosPage() {
  const [hasError, setHasError] = useState(false);
  const [dominio, setDominio] = useState('');
  const [fetchedData, setFetchedData] = useState([]);
  const [firstFetch, setFirstFetch] = useState(false);

  // const buttonRef = React.useRef(null);

  // const { ExcelFile } = ReactExport;
  // const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

  // Traer el token de autorizacion
  const { token } = useToken();

  // Traer el hook de tablas
  const {
    setData,
    setRawData,
    setCurrentPage,
    rawData,
    dataToShow,
    currentPage,
    totalRegisters,
    totalPages,
  } = useTableData();

  const fetchCargas = () => {
    api
      .get(`vehiculos/cargas/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        setHasError(false);
        setFetchedData(response.data);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  useEffect(() => {
    fetchCargas();

    // Forzar un seteo de data al inicio
    setDominio('');
    setFirstFetch(true);

    const intervalId = setInterval(fetchCargas, 5000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!dominio) {
      setData(fetchedData);
      setRawData(fetchedData);
    }
  }, [dominio]);

  useEffect(() => {
    if (firstFetch) {
      setFirstFetch(false);
    }
  }, [firstFetch]);

  const doFilter = (filtro) => {
    // Si hay algo para filtrar
    if (filtro !== '') {
      const fd = rawData.filter(
        (c) =>
          c.dominio.toLowerCase().indexOf(filtro.toLowerCase()) > -1 ||
          (c.interno && c.interno.toString().indexOf(filtro) > -1),
      );

      // Establecer la data
      setData(fd);
      // Ir a la primer pagina
      setCurrentPage(1);
    } else {
      setData(rawData);
    }
  };

  const handleSearch = (e, { value }) => {
    setDominio(value);
    doFilter(value);
  };

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  // const handleDescarga = () => {
  //   if (buttonRef.current !== null) {
  //     buttonRef.current.click();
  //   }
  // };

  return (
    <>
      <HeaderBar titulo="Estado de cargas" />
      <div className={styles.pageContainer}>
        <ModalError visible={hasError} onClose={() => setHasError(false)} />

        <div className={styles.tableContainer}>
          <Form>
            <Grid stackable columns={4}>
              <Grid.Row>
                <Grid.Column>
                  <Input
                    fluid
                    icon="search"
                    placeholder="Buscar domino/interno..."
                    value={dominio}
                    onChange={handleSearch}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>

          <Table unstackable selectable verticalAlign="middle">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Dominio</Table.HeaderCell>
                <Table.HeaderCell>Interno</Table.HeaderCell>
                <Table.HeaderCell>Fecha ultima carga</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Cargas Necesarias</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Cargas Realizadas</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Cargas Pendientes</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {dataToShow.map((carga) => (
                <Table.Row key={carga.dominio}>
                  <Table.Cell collapsing>
                    <Label as="a" color="violet">
                      {carga.dominio}
                    </Label>
                  </Table.Cell>
                  <Table.Cell collapsing>{carga.interno}</Table.Cell>
                  <Table.Cell>
                    {carga.ultima_carga === ''
                      ? 'N/D'
                      : formatInTimeZone(
                          new Date(carga.ultima_carga),
                          'UTC',
                          'dd/MM/yyyy HH:mm:ss',
                        )}
                  </Table.Cell>
                  <Table.Cell textAlign="center">{carga.cant_cargas}</Table.Cell>
                  <Table.Cell textAlign="center">{carga.cargas_realizadas}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {carga.cargas_restantes === 0 && (
                      <Label as="a" color="teal">
                        COMPLETO
                      </Label>
                    )}
                    {carga.cargas_restantes === 1 && (
                      <Label as="a" color="orange">
                        1 CARGA
                      </Label>
                    )}
                    {carga.cargas_restantes > 1 && (
                      <Label as="a" color="red">
                        {carga.cargas_restantes} CARGAS
                      </Label>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row verticalAlign="middle">
                <Table.HeaderCell colSpan="10">
                  <div className={styles.tableFooter}>
                    <Label>Cantidad de registros: {totalRegisters}</Label>
                    {totalPages > 1 && (
                      <Pagination
                        activePage={currentPage}
                        boundaryRange={1}
                        size="mini"
                        siblingRange={1}
                        totalPages={totalPages}
                        onPageChange={handlePaginationChange}
                      />
                    )}
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          {/* <Button floated="right" color="teal" 
              icon labelPosition="left" onClick={handleDescarga}>
            <Icon name="download" />
            Descargar XLS
          </Button> */}

          {/* <ExcelFile
            filename="Despachos"
            element={
              <Ref innerRef={buttonRef}>
                <button type="button" hidden>
                  hidden
                </button>
              </Ref>
            }
          >
            <ExcelSheet data={data} name="Despachos">
              <ExcelColumn label="ID" value="id" />
              <ExcelColumn
                label="Fecha"
                value={(col) => formatInTimeZone(new Date(col.fecha), 'UTC', 'dd/MM/yyyy HH:mm:ss')}
              />
              <ExcelColumn
                label="Vehiculo"
                value={(col) => (col.vehiculo ? col.vehiculo.dominio : '')}
              />

              <ExcelColumn
                label="Interno"
                value={(col) => (col.interno ? col.vehiculo.interno : '')}
              />
              <ExcelColumn label="Detalle" value="detalle" />
              <ExcelColumn label="Monto" value="monto" />
              <ExcelColumn label="PPU" value="ppu" />
              <ExcelColumn label="Volumen" value="volumen" />
            </ExcelSheet>
          </ExcelFile> */}
        </div>
      </div>
    </>
  );
}

export default CargasVehiculosPage;
