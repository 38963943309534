import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react-yz';
import { Button, Form, Modal, Message, Icon, Header } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';
import FormSelect from '../FormSelect/FormSelect';

// import styles from './FormNuevoDespacho.module.css';

import api from '../../api/api';
import useToken from '../../hooks/useToken';
import { SedeContext } from '../../context/SedeContext';

function FormNuevoDespacho({ visible, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState([]);
  const [fecha, setFecha] = useState('');
  const [hora, setHora] = useState('');
  const [surtidores, setSurtidores] = useState([]);
  const [productos, setProductos] = useState([]);
  const [tanques, setTanques] = useState([]);
  const [playeros, setPlayeros] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);

  const { control, handleSubmit, reset } = useForm();

  const { token } = useToken();

  // Traer el context
  const { sede } = useContext(SedeContext);

  useEffect(() => {
    fecthSurtidores();
    fecthProductos();
    fetchTanques();
    fetchVehiculos();
    fetchPlayeros();
  }, []);

  const fecthSurtidores = () => {
    setIsLoading(true);
    api
      .get(`surtidores/`, {
        headers: { Authorization: `Token ${token}` },
        params: { sede: sede.codigo },
      })
      .then((response) => {
        const surtidoresData = response.data;
        const surtOptions = [];
        surtidoresData.forEach((s) => {
          surtOptions.push({
            key: s.id,
            text: `Surtidor ${s.display_id}`,
            value: s.id,
          });
        });
        setSurtidores(surtOptions);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  const fecthProductos = () => {
    api
      .get('productos/', { headers: { Authorization: `Token ${token}` } })
      .then((response) => {
        const prodData = response.data;
        const prodOptions = [];
        prodData.forEach((p) => {
          prodOptions.push({
            key: p.id,
            text: p.nombre,
            value: p.id,
          });
        });
        setProductos(prodOptions);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  const fetchTanques = () => {
    api
      .get(`/tanques/`, {
        headers: { Authorization: `Token ${token}` },
        params: { sede: sede.codigo },
      })
      .then((response) => {
        const tkData = response.data;
        const tkOptions = [];
        tkData.forEach((tk) => {
          tkOptions.push({
            key: tk.id,
            text: `Tanque ${tk.display_id}`,
            value: tk.id,
          });
        });
        setTanques(tkOptions);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  const fetchVehiculos = () => {
    api
      .get(`/vehiculos/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        const vehiculosData = response.data;
        const vehiculosOptions = [];
        vehiculosData.forEach((v) => {
          vehiculosOptions.push({
            key: v.id,
            text: v.dominio,
            value: v.id,
          });
        });
        setVehiculos(vehiculosOptions);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  const fetchPlayeros = () => {
    api
      .get(`/playeros/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        const playerosData = response.data;
        const playerosOptions = [];
        playerosData.forEach((p) => {
          playerosOptions.push({
            key: p.id,
            text: p.nombre,
            value: p.id,
          });
        });
        setPlayeros(playerosOptions);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitForm = (data) => {
    setIsLoading(true);

    const dataNuevoDespacho = {
      ...data,
      fecha: fecha + 'T' + hora,
      monto: parseFloat(data.monto),
      volumen: parseFloat(data.volumen),
      ppu: parseFloat(data.ppu),
      codigo_sede: sede.codigo,
    };

    api
      .post('despachos/', dataNuevoDespacho, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        setHasError(false);
      })
      .catch((e) => {
        const errorData = e.response.data.error.details;
        const msgArray = [];

        errorData.forEach((m) => {
          msgArray.push(m.message);
        });

        setMsgError(msgArray);

        // Mostrar mensaje de error
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);

        // Reiniciar el formulario
        reset({
          username: '',
          password: '',
          confirm_password: '',
          email: '',
          codigo: '',
        });
      });
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    // <Modal dimmer="inverted" open={visible} size="small">
    //   <Modal.Header>Nuevo despacho manual</Modal.Header>
    //   <Modal.Content>
    //     <Form onSubmit={handleSubmit(submitForm)} id="formRegister" size="large" noValidate>
    //       <Header as="h4">Fecha y hora</Header>

    //       <Form.Group widths="equal">
    //
    //       </Form.Group>

    //       <Button type="submit" color="violet" fluid size="large" loading={isLoading}>
    //         Registrar
    //       </Button>
    //     </Form>

    //     <Message error hidden={!hasError}>
    //       <div className={styles.msgError}>
    //         {msgError.map((msg, index) => (
    //           <Message.Item key={index}>{msg}</Message.Item>
    //         ))}
    //       </div>
    //     </Message>
    //   </Modal.Content>
    // </Modal>
    <Modal dimmer="inverted" open={visible} size="small">
      <Modal.Header>Nuevo despacho manual</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={handleSubmit(submitForm)}
          onKeyDown={(e) => checkKeyDown(e)}
          id="formDespachoManual"
          noValidate
          loading={isLoading}
        >
          <Form.Group widths="equal">
            <DateInput
              required
              popupPosition="bottom left"
              autoComplete="off"
              closable
              clearable
              clearIcon={<Icon name="remove" color="red" />}
              name="fecha"
              placeholder="dd/mm/yyyy"
              preserveViewMode={false}
              value={fecha}
              onChange={(e, { value }) => setFecha(value)}
            />

            <TimeInput
              required
              popupPosition="bottom left"
              autoComplete="off"
              closable
              clearable
              clearIcon={<Icon name="remove" color="red" />}
              name="fecha"
              placeholder="hh:mm"
              preserveViewMode={false}
              value={hora}
              onChange={(e, { value }) => setHora(value)}
            />
          </Form.Group>

          <Header as="h4">Origen</Header>

          <Form.Group widths="equal">
            <FormSelect
              fluid
              required
              name="surtidor"
              iconPosition="left"
              placeholder="Seleccione surtidor"
              control={control}
              options={surtidores}
            />

            <FormSelect
              fluid
              required
              name="producto"
              iconPosition="left"
              placeholder="Seleccione producto"
              control={control}
              options={productos}
            />
            <FormSelect
              fluid
              required
              name="tanque"
              iconPosition="left"
              placeholder="Seleccione tanque"
              control={control}
              options={tanques}
            />
          </Form.Group>

          <Header as="h4">Transaccion</Header>

          <Form.Group widths="equal">
            <FormInput
              fluid
              required
              name="monto"
              icon="money bill alternate outline"
              iconPosition="left"
              placeholder="Monto"
              control={control}
              validationRules={{ pattern: /^\d*\.?\d*$/ }}
            />
            <FormInput
              fluid
              required
              name="volumen"
              icon="lab"
              iconPosition="left"
              placeholder="Volumen"
              control={control}
              validationRules={{ pattern: /^\d*\.?\d*$/ }}
            />
            <FormInput
              fluid
              required
              name="ppu"
              icon="dollar"
              iconPosition="left"
              placeholder="Precio"
              control={control}
              validationRules={{ pattern: /^\d*\.?\d*$/ }}
            />
          </Form.Group>

          <Header as="h4">Datos adicionales</Header>

          <Form.Group widths="equal">
            <FormSelect
              search
              name="vehiculo"
              fluid
              placeholder="Vehiculo"
              control={control}
              options={vehiculos}
            />

            <FormSelect
              search
              name="playero"
              fluid
              placeholder="Playero"
              options={playeros}
              control={control}
            />

            <FormInput
              fluid
              name="detalle"
              icon="sticky note outline"
              iconPosition="left"
              placeholder="Detalle"
              control={control}
            />
          </Form.Group>
        </Form>
        <Message hidden={!hasError} error header="Error" content={msgError} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button form="formDespachoManual" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

FormNuevoDespacho.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FormNuevoDespacho;
