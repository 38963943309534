/* eslint-disable react/jsx-props-no-spreading */
/* eslint no-unneeded-ternary: "error" */
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';

function FormCheck({ control, label, name, onSelectionChanged, ...props }) {
  return (
    <Controller
      defaultValue={false}
      control={control}
      rules={{ required: props.required }}
      name={name ? name : label.toLowerCase()}
      render={({ field: { onChange, value: selectedValue }, fieldState: { invalid } }) => (
        <Form.Checkbox
          {...props}
          label={label}
          onChange={(e, { checked }) => {
            onChange(checked);
            if (onSelectionChanged) onSelectionChanged(checked);
          }}
          checked={selectedValue}
          error={invalid}
        />
      )}
    />
  );
}

FormCheck.propTypes = {
  control: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSelectionChanged: PropTypes.func,
  required: PropTypes.bool,
};

export default FormCheck;
