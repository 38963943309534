import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

import FormInput from '../FormInput/FormInput';

import useToken from '../../hooks/useToken';

import { SedeContext } from '../../context/SedeContext';

import api from '../../api/api';

function CargaSaldo({ visible, onClose, vehiculoEdit, onEdit }) {
  const [msg, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Traer la sede seleccionada
  const { sede } = useContext(SedeContext);

  // Traer el token de autorizacion
  const { token } = useToken();

  const { control, handleSubmit } = useForm();

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const submitForm = (data) => {
    setIsLoading(true);

    const credito = {
      ...data,
      codigo_sede: sede.codigo,
      credito: parseFloat(data.credito),
      fecha: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      vehiculo: vehiculoEdit.id,
    };

    api
      .post(`/creditos/`, credito, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        onEdit();
        setHasError(false);
        cerrarFormulario();
      })
      .catch((error) => {
        setHasError(true);
        console.log(error.response);
        setErrorMessage('Error al comunicarse con el servidor');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal dimmer="blurring" open={visible} size="mini">
      <Modal.Header>{`Carga de saldo - ${
        vehiculoEdit.dominio === null ? '' : vehiculoEdit.dominio
      }`}</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={handleSubmit(submitForm)}
          id="formCargaSaldo"
          noValidate
          loading={isLoading}
        >
          <FormInput
            required
            name="detalle"
            fluid
            label="Detalle"
            placeholder="Detalle"
            control={control}
          />
          <FormInput
            required
            name="credito"
            fluid
            label="Credito"
            placeholder="0.00 $"
            control={control}
            validationRules={{ pattern: /^[0-9.]*$/ }}
          />
        </Form>
        <Message hidden={!hasError} error header="Error" content={msg} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formCargaSaldo" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

CargaSaldo.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  vehiculoEdit: PropTypes.object.isRequired,
};

export default CargaSaldo;
