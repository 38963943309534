import React from 'react';
import { Message } from 'semantic-ui-react';

import styles from './MensajeOffline.module.css';

function MensajeOffline() {
  return (
    <Message attached error>
      <div className={styles.msgError}>
        El servidor de despachos se encuentra desconectado. La funcionalidad del sistema SAF Flotas
        se encuentra limitada al modo de solo lectura. <a href>Mas información</a>
      </div>
    </Message>
  );
}

export default MensajeOffline;
