/* eslint-disable react/jsx-props-no-spreading */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';

import SidebarMenu from '../../components/Sidebar/SidebarMenu';

import Login from '../../components/Login/Login';
import CambioPassword from '../../components/CambioPassword/CambioPassword';

import VehiculosPage from '../VehiculosPage/VehiculosPage';
import PlayaPage from '../PlayaPage/PlayaPage';
import DespachosPage from '../DespachosPage/DespachosPage';
import TurnosPage from '../TurnosPage/TurnosPage';
import ConsumosPage from '../ConsumosPage/ConsumosPage';
import TanquesPage from '../TanquesPage/TanquesPage';
import MovimientosPage from '../MovimientosPage/MovimientosPage';
import CargasVehiculosPage from '../CargasVehiculosPage/CargasVehiculosPage';
import DesbloqueosPage from '../DesbloqueosPage/DesbloqueosPage';
import PreciosPage from '../PreciosPage/PreciosPage';
import PlayerosPage from '../PlayerosPage/PlayerosPage';
import PreferenciasPage from '../PreferenciasPage/PreferenciasPage';
import FlotasPage from '../FlotasPage/FlotasPage';
import UserNotFound from '../../components/UserNotFound/UserNotFound';
import CrearUsuarioFlota from '../../components/CrearUsuarioFlota/CrearUsuarioFlota';

import styles from './HomePage.module.css';

import useToken from '../../hooks/useToken';
import UserVerificado from '../../components/UserVerificado/UserVerificado';
import MensajeOffline from '../../components/MensajeOffline/MensajeOffline';
import { UserFlagsContext } from '../../context/UserFlagContext';

function HomePage() {
  const { serverLocalConectado, usuarioLogeado } = useContext(UserFlagsContext);

  const { token } = useToken();

  const locationActual = useLocation();

  const PrivateRoute = ({ page: Page, ...props }) => {
    // const token = getToken();
    return (
      <Route
        {...props}
        render={(routeProps) =>
          token && usuarioLogeado ? <Page {...routeProps} /> : <Redirect to="/" />
        }
      />
    );
  };

  // Sino, devolver la app principal
  return (
    <div className={styles.bodyContainer}>
      <div className={styles.msgErrorContainer}>
        {/* Render del mensaje de cliente offline */}
        {locationActual.pathname !== '/' &&
          locationActual.pathname !== '/changepassword' &&
          locationActual.pathname !== '/verificar' &&
          locationActual.pathname !== '/userflota' &&
          locationActual.pathname !== '/tokennotfound' &&
          !serverLocalConectado && <MensajeOffline />}
      </div>

      <div className={styles.mainContainer}>
        {/* Render del sidebar si no estoy en ninguna de las rutas admin */}
        {locationActual.pathname !== '/' &&
          locationActual.pathname !== '/changepassword' &&
          locationActual.pathname !== '/verificar' &&
          locationActual.pathname !== '/userflota' &&
          locationActual.pathname !== '/tokennotfound' && <SidebarMenu />}

        <div className={styles.pageContainer}>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/userflota" exact component={CrearUsuarioFlota} />
            <Route path="/changepassword" exact component={CambioPassword} />
            <Route path="/tokennotfound" exact component={UserNotFound} />
            <Route path="/verificar" exact component={UserVerificado} />
            <PrivateRoute path="/vehiculos" exact page={VehiculosPage} />
            <PrivateRoute path="/playa" exact page={PlayaPage} />
            <PrivateRoute path="/despachos" exact page={DespachosPage} />
            <PrivateRoute path="/turnos" exact page={TurnosPage} />
            <PrivateRoute path="/precios" exact page={PreciosPage} />
            <PrivateRoute path="/consumos" exact page={ConsumosPage} />
            <PrivateRoute path="/tanques" exact page={TanquesPage} />
            <PrivateRoute path="/movimientos" exact page={MovimientosPage} />
            <PrivateRoute path="/cargas" exact page={CargasVehiculosPage} />
            <PrivateRoute path="/desbloqueos" exact page={DesbloqueosPage} />
            <PrivateRoute path="/playeros" exact page={PlayerosPage} />
            <PrivateRoute path="/preferencias" exact page={PreferenciasPage} />
            <PrivateRoute path="/flotas" exact page={FlotasPage} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

HomePage.propTypes = {
  page: PropTypes.func,
};

export default HomePage;
