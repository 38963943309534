import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';
import FormCheck from '../FormCheck/FormCheck';

import useToken from '../../hooks/useToken';

import api from '../../api/api';

function OpcionesAvanzadas({ visible, onClose, vehiculoEdit, onEdit }) {
  const [msg, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Traer el token de autorizacion
  const { token } = useToken();

  const { control, handleSubmit, setValue } = useForm();

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const submitForm = (data) => {
    setIsLoading(true);
    console.log(data);

    api
      .patch(`vehiculos/${vehiculoEdit.id}/`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        onEdit();
        setHasError(false);
        cerrarFormulario();
      })
      .catch((error) => {
        setHasError(true);
        console.log(error.response);
        setErrorMessage('Error al comunicarse con el servidor');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Prepopular el form
  useEffect(() => {
    if (vehiculoEdit !== null) {
      console.log(vehiculoEdit);
      setValue('cant_cargas', vehiculoEdit.cant_cargas);
      setValue('alerta_tablero', vehiculoEdit.alerta_tablero);
      setValue('precinto_habilitado', vehiculoEdit.precinto_habilitado);
      setValue('precinto1', vehiculoEdit.precinto1);
      setValue('precinto2', vehiculoEdit.precinto2);
    }
  }, [vehiculoEdit]);

  return (
    <Modal dimmer="blurring" open={visible} size="tiny">
      <Modal.Header>{`Opciones Avanzadas - ${
        vehiculoEdit.dominio === null ? '' : vehiculoEdit.dominio
      }`}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit(submitForm)} id="formOpciones" noValidate loading={isLoading}>
          <FormCheck name="alerta_tablero" control={control} label="Alertar en tablero" />
          <FormInput
            name="cant_cargas"
            fluid
            label="Cantidad de cargas"
            placeholder="0"
            control={control}
            validationRules={{ pattern: /^[0-9]*$/ }}
          />
          <FormCheck name="precinto_habilitado" control={control} label="Precintos de seguridad" />
          <Form.Group widths="equal">
            <FormInput
              name="precinto1"
              fluid
              label="Precinto #1"
              placeholder="0"
              control={control}
              validationRules={{ pattern: /^[0-9]*$/ }}
            />
            <FormInput
              name="precinto2"
              fluid
              label="Precinto #2"
              placeholder="0"
              control={control}
              validationRules={{ pattern: /^[0-9]*$/ }}
            />
          </Form.Group>
        </Form>
        <Message hidden={!hasError} error header="Error" content={msg} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formOpciones" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

OpcionesAvanzadas.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  vehiculoEdit: PropTypes.object.isRequired,
};

export default OpcionesAvanzadas;
