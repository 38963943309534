import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';
import FormCheck from '../FormCheck/FormCheck';
import FormSelect from '../FormSelect/FormSelect';

import useToken from '../../hooks/useToken';

import api from '../../api/api';

function PreciosDiferenciados({ visible, onClose, vehiculoEdit, onEdit, productos }) {
  const [habilitado, setHabilitado] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [msg, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Traer el token de autorizacion
  const { token } = useToken();

  const { control, handleSubmit, setValue } = useForm();

  // Prepopular el form
  useEffect(() => {
    if (vehiculoEdit !== null) {
      setHabilitado(vehiculoEdit.precio_diferenciado_habilitado);
      setValue('precio_diferenciado_habilitado', vehiculoEdit.precio_diferenciado_habilitado);
      setValue(
        'precio_diferenciado',
        vehiculoEdit.precio_diferenciado === null ? '' : vehiculoEdit.precio_diferenciado,
      );
      setValue('precio_diferenciado_producto', vehiculoEdit.precio_diferenciado_producto);
    }
  }, [vehiculoEdit]);

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const submitForm = (data) => {
    const dataPrecio = {
      ...data,
      precio_diferenciado:
        data.precio_diferenciado === '' ? 0 : parseFloat(data.precio_diferenciado),
    };

    setIsLoading(true);

    api
      .patch(`vehiculos/${vehiculoEdit.id}/`, dataPrecio, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        onEdit();
        setHasError(false);
        cerrarFormulario();
      })
      .catch(() => {
        setHasError(true);
        setErrorMessage('Error al comunicarse con el servidor');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal dimmer="blurring" open={visible} size="tiny">
      <Modal.Header>{`Precios Diferenciados - ${
        vehiculoEdit.dominio === null ? '' : vehiculoEdit.dominio
      }`}</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={handleSubmit(submitForm)}
          id="formPreciosDiferenciados"
          noValidate
          loading={isLoading}
        >
          <FormCheck
            name="precio_diferenciado_habilitado"
            control={control}
            label="Habilitado"
            onSelectionChanged={(value) => setHabilitado(value)}
          />

          <FormSelect
            required
            disabled={!habilitado}
            name="precio_diferenciado_producto"
            label="Producto"
            options={productos}
            placeholder="Seleccione"
            control={control}
          />

          <FormInput
            icon="dollar"
            disabled={!habilitado}
            name="precio_diferenciado"
            fluid
            label="Precio diferenciado"
            placeholder="0"
            control={control}
            validationRules={{ pattern: /^[0-9.]*$/ }}
          />
        </Form>
        <Message hidden={!hasError} error header="Error" content={msg} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formPreciosDiferenciados" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

PreciosDiferenciados.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  vehiculoEdit: PropTypes.object.isRequired,
  productos: PropTypes.object.isRequired,
};

export default PreciosDiferenciados;
