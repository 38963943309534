/* eslint-disable no-nested-ternary */

import React, { useEffect, useState, useContext } from 'react';
import {
  Icon,
  Pagination,
  Table,
  Input,
  Grid,
  Button,
  Label,
  Ref,
  Modal,
  Loader,
  Container,
} from 'semantic-ui-react';
import { formatInTimeZone } from 'date-fns-tz';
import ReactExport from 'react-export-excel';

import styles from './DespachosPage.module.css';
import HeaderBar from '../../components/HeaderBar/HeaderBar';

import EditarDespacho from '../../components/EditarDespacho/EditarDespacho';
import AsignarVehiculo from '../../components/AsignarVehiculo/AsignarVehiculo';
import ModalError from '../../components/ModalError/ModalError';

import useToken from '../../hooks/useToken';
import useTableData from '../../hooks/useTableData';

import { SedeContext } from '../../context/SedeContext';

import api from '../../api/api';
import FormNuevoDespacho from '../../components/FormNuevoDespacho/FormNuevoDespacho';
import FiltroDespachos from '../../components/FiltroDespachos/FiltroDespachos';

function DespachosPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [modalSinDatos, setModalSinDatos] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [mostrarInterno, setMostrarInterno] = useState(false);
  const [mostrarPlayero, setMostrarPlayero] = useState(false);
  const [dominio, setDominio] = useState('');
  const [flota, setFlota] = useState('');
  const [filtroDespachos, setFiltroDespachos] = useState(false);
  const [totalMonto, setTotalMonto] = useState(0);
  const [totalVolumen, setTotalVolumen] = useState(0);
  const [despachoEdit, setDespachoEdit] = useState({});
  const [vehiculoAsignado, setVehiculoAsignado] = useState({});
  const [editarDespacho, setEditarDespacho] = useState(false);
  const [nuevoRegistro, setNuevoRegistro] = useState(false);
  const [asignarVehiculo, setAsignarVehiculo] = useState(false);

  const buttonRef = React.useRef(null);

  const { ExcelFile } = ReactExport;
  const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

  // Traer el context
  const { sede } = useContext(SedeContext);

  // Traer el token de autorizacion
  const { user, token } = useToken();

  // Traer el hook de tablas
  const {
    setData,
    setRawData,
    setCurrentPage,
    rawData,
    data,
    dataToShow,
    currentPage,
    totalRegisters,
    totalPages,
  } = useTableData();

  useEffect(() => {
    // Traer los surtidores y armar el array de opciones
    // fetchSurtidores();
    // fecthProductos();

    // Borrar los datos de consulta
    setRawData([]);
    setData([]);
  }, [sede]);

  const doFilter = (filtro) => {
    // Si hay algo para filtrar
    if (filtro !== '') {
      const fd = rawData
        // Remover los valores con vehiculos nulos
        .filter((d) => d.vehiculo)

        // Filtrar
        .filter(
          (d) =>
            d.vehiculo.dominio.toLowerCase().indexOf(filtro.toLowerCase()) > -1 ||
            (d.vehiculo.interno && d.vehiculo.interno.toString().indexOf(filtro) > -1),
          // || (d.vehiculo.flota && d.flota.toString().indexOf(filtro) > -1),
        );

      // Establecer la data
      setData(fd);
      // Ir a la primer pagina
      setCurrentPage(1);

      // Si el filtro arrojo resultados, calcular los totales
      if (fd.length !== 0) {
        const tVolumen = fd.map((d) => d.volumen).reduce((sum, d) => sum + d);
        const tMonto = fd.map((d) => d.monto).reduce((sum, d) => sum + d);

        setTotalMonto(tMonto);
        setTotalVolumen(tVolumen);
      }
    } else {
      setData(rawData);

      // Calcular los totales sin filtrar
      const tVolumen = rawData.map((d) => d.volumen).reduce((sum, d) => sum + d);
      const tMonto = rawData.map((d) => d.monto).reduce((sum, d) => sum + d);

      setTotalMonto(tMonto);
      setTotalVolumen(tVolumen);
    }
  };

  const handleSearch = (e, { value }) => {
    setDominio(value);
    doFilter(value);
  };

  const handleDespachoEdit = (d) => {
    let updated = rawData.map((item) => {
      if (item.id === despachoEdit.id) {
        return { ...item, detalle: d.detalle, tipo: d.tipo };
      }
      return item;
    });

    // Actualizar el dataset completo de referencia
    setRawData(updated);

    // Actualizar el dataset que se esta mostrando
    updated = data.map((item) => {
      if (item.id === despachoEdit.id) {
        return { ...item, detalle: d.detalle, tipo: d.tipo };
      }
      return item;
    });
    setData(updated);
  };

  const handleAsignarVehiculoEdit = (d) => {
    let updated = rawData.map((item) => {
      if (item.id === vehiculoAsignado.despacho_id) {
        return { ...item, vehiculo: d.vehiculo };
      }
      return item;
    });

    // Actualizar el dataset completo de referencia
    setRawData(updated);

    // Actualizar el dataset que se esta mostrando
    updated = data.map((item) => {
      if (item.id === vehiculoAsignado.despacho_id) {
        return { ...item, vehiculo: d.vehiculo };
      }
      return item;
    });
    setData(updated);
  };

  const handleNuevoRegistro = () => {
    setNuevoRegistro(true);
  };

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handleDescarga = () => {
    if (buttonRef.current !== null) {
      buttonRef.current.click();
    }
  };

  const buscarDespachos = (filtro, mostrarInterno, mostrarPlayero, flota) => {
    setIsLoading(true);

    setMostrarInterno(mostrarInterno);
    setMostrarPlayero(mostrarPlayero);

    api
      .get(`despachos/`, {
        headers: { Authorization: `Token ${token}` },
        params: filtro,
      })
      .then((response) => {
        setRawData(response.data);
        setData(response.data);
        setCurrentPage(1);

        if (response.data.length != 0) {
          const tVolumen = response.data.map((d) => d.volumen).reduce((sum, d) => sum + d);
          const tMonto = response.data.map((d) => d.monto).reduce((sum, d) => sum + d);
          setTotalMonto(tMonto);
          setTotalVolumen(tVolumen);
        } else {
          setModalSinDatos(true);
        }

        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
        setFlota(flota);
      });
  };

  return (
    <>
      <HeaderBar titulo="Historial de despachos" />
      <div className={styles.pageContainer}>
        <ModalError visible={hasError} onClose={() => setHasError(false)} />

        {modalSinDatos && (
          <Modal dimmer="blurring" open={modalSinDatos} size="tiny">
            <Modal.Header>Despachos</Modal.Header>
            <Modal.Content>No existe ningun despacho entre las fechas seleccionadas</Modal.Content>
            <Modal.Actions>
              <Button primary onClick={() => setModalSinDatos(false)}>
                OK
              </Button>
            </Modal.Actions>
          </Modal>
        )}

        {isLoading && (
          <Modal dimmer="inverted" open={isLoading} size="mini">
            {/* <Modal.Header>
              <Icon name="circle notch" loading />
              Buscando despachos...
            </Modal.Header> */}
            <Modal.Content>
              <Container className={styles.modalContainer}>
                <Grid stackable columns={1} rows={2}>
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <Loader active />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign="center">Buscando despachos</Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </Modal.Content>
          </Modal>
        )}

        {editarDespacho && (
          <EditarDespacho
            visible={editarDespacho}
            onClose={() => setEditarDespacho(false)}
            despacho={despachoEdit}
            onEdit={handleDespachoEdit}
          />
        )}

        {nuevoRegistro && (
          <FormNuevoDespacho visible={nuevoRegistro} onClose={() => setNuevoRegistro(false)} />
        )}

        {asignarVehiculo && (
          <AsignarVehiculo
            visible={asignarVehiculo}
            onClose={() => setAsignarVehiculo(false)}
            vehiculo={vehiculoAsignado}
            onEdit={handleAsignarVehiculoEdit}
          />
        )}

        {filtroDespachos && (
          <FiltroDespachos
            visible={filtroDespachos}
            onClose={() => setFiltroDespachos(false)}
            onAccept={buscarDespachos}
          />
        )}

        <div className={styles.tableContainer}>
          <Grid stackable columns={4}>
            <Grid.Row>
              <Grid.Column>
                <Button
                  primary
                  icon
                  labelPosition="left"
                  // loading={isLoading}
                  onClick={() => setFiltroDespachos(true)}
                >
                  <Icon name="filter" />
                  Consultar
                </Button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Input
                  fluid
                  icon="search"
                  placeholder="Buscar..."
                  value={dominio}
                  onChange={handleSearch}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                {flota != '' && (
                  <Label basic color="teal">
                    {`Flota: ${flota}`}
                  </Label>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Table compact unstackable sortable selectable verticalAlign="middle">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Fecha</Table.HeaderCell>
                <Table.HeaderCell>Vehiculo</Table.HeaderCell>
                {mostrarPlayero && <Table.HeaderCell>Playero</Table.HeaderCell>}
                <Table.HeaderCell>Producto</Table.HeaderCell>
                <Table.HeaderCell>Detalle</Table.HeaderCell>
                <Table.HeaderCell>Monto</Table.HeaderCell>
                <Table.HeaderCell>PPU</Table.HeaderCell>
                <Table.HeaderCell>Volumen</Table.HeaderCell>
                {/* <Table.HeaderCell>Tipo</Table.HeaderCell> */}
                <Table.HeaderCell>Opciones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {dataToShow.map((despacho) => (
                <Table.Row key={despacho.id}>
                  <Table.Cell collapsing>{despacho.numero_despacho}</Table.Cell>
                  <Table.Cell collapsing>
                    {formatInTimeZone(new Date(despacho.fecha), 'UTC', 'dd/MM/yyyy HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {despacho.vehiculo && (
                      <Label basic color="blue">
                        {mostrarInterno
                          ? despacho.vehiculo.interno !== null
                            ? despacho.vehiculo.interno
                            : 'Int. Desconocido'
                          : despacho.vehiculo.dominio}
                      </Label>
                    )}
                  </Table.Cell>

                  {mostrarPlayero && (
                    <Table.Cell collapsing>
                      {despacho.playero ? despacho.playero.nombre : ''}
                    </Table.Cell>
                  )}

                  {/* <Table.Cell collapsing>
                    {despacho.vehiculo ? despacho.vehiculo.interno : 'N/D'}
                  </Table.Cell> */}
                  <Table.Cell>{despacho.producto}</Table.Cell>
                  <Table.Cell>{despacho.detalle}</Table.Cell>
                  <Table.Cell>$ {despacho.monto}</Table.Cell>
                  <Table.Cell>$ {despacho.ppu}</Table.Cell>
                  <Table.Cell>{despacho.volumen} Lts. </Table.Cell>
                  {/* <Table.Cell collapsing>
                    {despacho.tipo === 0 && <Label color="blue">ESTANDAR</Label>}
                    {despacho.tipo === 1 && <Label color="teal">PRUEBA SURT.</Label>}
                    {despacho.tipo === 2 && <Label color="olive">PRUEBA SURT. RET.</Label>}
                  </Table.Cell> */}
                  <Table.Cell collapsing>
                    <Button
                      disabled={user.permiso < 2}
                      circular
                      icon="edit outline"
                      size="tiny"
                      onClick={() => {
                        setEditarDespacho(true);
                        setDespachoEdit({
                          id: despacho.id,
                          detalle: despacho.detalle,
                          tipo: despacho.tipo,
                        });
                      }}
                    />

                    <Button
                      disabled={user.permiso < 2 || despacho.vehiculo !== null}
                      circular
                      icon="car"
                      size="tiny"
                      onClick={() => {
                        setAsignarVehiculo(true);
                        setVehiculoAsignado({
                          despacho_id: despacho.id,
                          vehiculo_id: despacho.vehiculo,
                          dominio: despacho.vehiculo ? despacho.vehiculo.dominio : '',
                        });
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
              {dataToShow.length > 0 && (
                <Table.Row key={-1}>
                  {/* Header */}
                  <Table.Cell />

                  {/* Fecha */}
                  <Table.Cell collapsing>
                    <b>TOTALES</b>
                  </Table.Cell>

                  {/* Vehiculo */}
                  <Table.Cell />

                  {/* Playero */}
                  {mostrarPlayero && <Table.Cell />}

                  {/* Producto */}
                  <Table.Cell />

                  {/* Detalle */}
                  <Table.Cell />

                  {/* Monto */}
                  <Table.Cell>
                    <b>$ {(Math.round(totalMonto * 100) / 100).toFixed(2)}</b>
                  </Table.Cell>

                  {/* PPU */}
                  <Table.Cell />

                  {/* Volumen */}
                  <Table.Cell>
                    <b>{(Math.round(totalVolumen * 100) / 100).toFixed(2)} Lts</b>
                  </Table.Cell>

                  {/* Opciones */}
                  <Table.Cell />
                </Table.Row>
              )}
            </Table.Body>

            <Table.Footer>
              <Table.Row verticalAlign="middle">
                <Table.HeaderCell colSpan="11">
                  <div className={styles.tableFooter}>
                    <Label>Cantidad de registros: {totalRegisters}</Label>
                    {totalPages > 1 && (
                      <Pagination
                        activePage={currentPage}
                        boundaryRange={1}
                        size="mini"
                        siblingRange={1}
                        totalPages={totalPages}
                        onPageChange={handlePaginationChange}
                      />
                    )}
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <Button
            // disabled={user.permiso < 2}
            disabled
            floated="right"
            color="teal"
            icon
            labelPosition="left"
            onClick={handleNuevoRegistro}
          >
            <Icon name="add circle" />
            Nuevo Registro
          </Button>

          <Button
            disabled={user.permiso < 2}
            floated="right"
            color="teal"
            icon
            labelPosition="left"
            onClick={handleDescarga}
          >
            <Icon name="download" />
            Descargar XLS
          </Button>

          <ExcelFile
            filename="Despachos"
            element={
              <Ref innerRef={buttonRef}>
                <button type="button" hidden>
                  hidden
                </button>
              </Ref>
            }
          >
            <ExcelSheet data={data} name="Despachos">
              <ExcelColumn label="ID" value="id" />
              <ExcelColumn
                label="Fecha"
                value={(col) => formatInTimeZone(new Date(col.fecha), 'UTC', 'dd/MM/yyyy HH:mm:ss')}
              />
              <ExcelColumn
                label="Vehiculo"
                value={(col) => (col.vehiculo ? col.vehiculo.dominio : '')}
              />

              <ExcelColumn
                label="Interno"
                value={(col) => (col.vehiculo ? col.vehiculo.interno : '')}
              />

              <ExcelColumn
                label="Flota"
                value={(col) => (col.vehiculo ? col.vehiculo.flota : '')}
              />

              <ExcelColumn
                label="Playero"
                value={(col) => (col.playero ? col.playero.nombre : '')}
              />

              <ExcelColumn label="Producto" value="producto" />
              <ExcelColumn label="Detalle" value="detalle" />
              <ExcelColumn label="Monto" value="monto" />
              <ExcelColumn label="PPU" value="ppu" />
              <ExcelColumn label="Volumen" value="volumen" />
            </ExcelSheet>
          </ExcelFile>
        </div>
      </div>
    </>
  );
}

export default DespachosPage;
