import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumb, Header, Icon, Dropdown } from 'semantic-ui-react';

import styles from './HeaderBar.module.css';

import ModalPerfil from '../ModalPerfil/ModalPerfil';

import useToken from '../../hooks/useToken';
import { SedeContext } from '../../context/SedeContext';

function HeaderBar({ titulo, subtitulo }) {
  const { setSede, sede, listaSedes } = useContext(SedeContext);
  const [mostrarPerfil, setMostrarPerfil] = useState(false);

  const { user, setUser } = useToken();

  const history = useHistory();

  const cerrarSesion = () => {
    localStorage.clear();

    setUser({
      token: '',
      username: '',
      role: 0,
    });

    history.push('/');
  };

  useEffect(() => {
    // console.log(sede);
  }, []);

  const mostrarPreferencias = () => {
    history.push('/preferencias');
  };

  const cambiarSede = (id, codigo, nombre) => {
    setSede({
      id: id,
      nombre: nombre,
      codigo: codigo,
    });
  };

  const sections = [
    { key: 'Home', content: 'Inicio', link: true },
    { key: 'Alta', content: titulo, link: true },
  ];

  return (
    <div className={styles.headerContainer}>
      {mostrarPerfil && (
        <ModalPerfil visible={mostrarPerfil} onClose={() => setMostrarPerfil(false)}></ModalPerfil>
      )}
      <div className={styles.titulo}>
        <Header
          as="h2"
          content={titulo}
          subheader={subtitulo ? subtitulo : 'Seleccione una opcion del menu para continuar'}
        />
        <Breadcrumb icon="right angle" sections={sections} />
      </div>
      <div className={styles.sede}>
        <Icon name="map pin" size="big" color="teal" />
        <Dropdown
          disabled={user.role < 2}
          text={`Sede ${sede.id} - ${sede.nombre}`}
          inline
          floating
          direction="left"
        >
          <Dropdown.Menu>
            {listaSedes.map((s) => (
              <Dropdown.Item
                key={s.id}
                text={`Sede ${s.id} - ${s.nombre}`}
                onClick={() => cambiarSede(s.id, s.codigo, s.nombre)}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={styles.avatar}>
        <Icon name="user circle" size="big" color="violet" />
        <Dropdown text={`Hola, ${user.username}!`} inline floating direction="left">
          <Dropdown.Menu>
            <Dropdown.Item text="Perfil" onClick={() => setMostrarPerfil(true)} />
            <Dropdown.Item disabled text="Preferencias" onClick={mostrarPreferencias} />
            <Dropdown.Divider />
            <Dropdown.Item text="Cerrar Sesion" onClick={cerrarSesion} />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

HeaderBar.propTypes = {
  titulo: PropTypes.string,
  subtitulo: PropTypes.string,
};

HeaderBar.defaultProps = {
  titulo: 'Hola!',
};

export default HeaderBar;
