import React, { useEffect, useState, useContext } from 'react';
import { Card } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';

import styles from './PlayaPage.module.css';
import HeaderBar from '../../components/HeaderBar/HeaderBar';

import CardSurtidor from '../../components/CardSurtidor/CardSurtidor';
import UltimosDespachos from '../../components/UltimosDespachos/UltimosDespachos';
import CalibracionSurtidor from '../../components/CalibracionSurtidor/CalibracionSurtidor';
import ConfirmarAutorizacion from '../../components/ConfirmarAutorizacion/ConfirmarAutorizacion';
import ModoAutorizacion from '../../components/ModoAutorizacion/ModoAutorizacion';

import api from '../../api/api';

import useToken from '../../hooks/useToken';
import { SedeContext } from '../../context/SedeContext';

function PlayaPage() {
  const [surtidores, setSurtidores] = useState([]);
  const [surtidor, setSurtidor] = useState(0);
  const [ultimosDespachos, setUltimosDespachos] = useState(false);
  const [calibracionSurtidor, setCalibacionSurtidor] = useState(false);
  const [confirmarAutorizacion, setConfirmarAutorizacion] = useState(false);
  const [modoAutorizacion, setModoAutorizacion] = useState(false);
  const { sede } = useContext(SedeContext);

  const { token } = useToken();

  const fetchApi = () =>
    api
      .get(`surtidores/`, {
        headers: { Authorization: `Token ${token}` },
        params: { sede: sede.codigo },
      })
      .then((response) => {
        setSurtidores(response.data);
      });

  useEffect(() => {
    fetchApi();
    const intervalId = setInterval(fetchApi, 1000);
    return () => clearInterval(intervalId);
  }, [sede]);

  const isLaptop = useMediaQuery({ query: '(max-width: 1280px)' });

  return (
    <>
      <HeaderBar titulo="Estado de Playa" />
      <div className={styles.pageContainer}>
        {ultimosDespachos && (
          <UltimosDespachos
            visible={ultimosDespachos}
            surtidor={surtidor}
            onClose={() => setUltimosDespachos(false)}
          />
        )}

        {calibracionSurtidor && (
          <CalibracionSurtidor
            visible={calibracionSurtidor}
            surtidor={surtidor}
            onClose={() => setCalibacionSurtidor(false)}
          />
        )}

        {confirmarAutorizacion && (
          <ConfirmarAutorizacion
            visible={confirmarAutorizacion}
            surtidor={surtidor}
            onClose={() => setConfirmarAutorizacion(false)}
          />
        )}

        {modoAutorizacion && (
          <ModoAutorizacion
            visible={modoAutorizacion}
            surtidor={surtidor}
            onClose={() => setModoAutorizacion(false)}
          />
        )}

        <Card.Group itemsPerRow={isLaptop ? 4 : 5}>
          {surtidores.map((surt) => (
            <CardSurtidor
              key={surt.id}
              surtidor={surt}
              onClickUltimosDespachos={() => {
                setSurtidor(surt);
                setUltimosDespachos(true);
              }}
              onClickCalibracion={() => {
                setSurtidor(surt);
                setCalibacionSurtidor(true);
              }}
              onClickAutorizar={() => {
                setSurtidor(surt);
                setConfirmarAutorizacion(true);
              }}
              onClickModoAutorizacion={() => {
                setSurtidor(surt);
                setModoAutorizacion(true);
              }}
            />
          ))}
        </Card.Group>
      </div>
    </>
  );
}

export default PlayaPage;
