import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Header, Image } from 'semantic-ui-react';

import styles from './UserNotFound.module.css';
import logo from '../../assets/img/logodrz.png';

function UserNotFound() {
  const history = useHistory();

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image src={logo} width="50%" centered />
        <Header as="h2" color="violet" textAlign="center">
          El link de cambio de contraseña es invalido o esta expirado
        </Header>

        <div className={styles.footer}>
          <button
            type="button"
            className={styles.linkButton}
            onClick={() => (window.location = 'mailto:info@drz-servicios.com')}
          >
            Contactarnos
          </button>
          <button type="button" className={styles.linkButton} onClick={() => history.push('/')}>
            Volver
          </button>
        </div>

        <div className={styles.footerRegister}>
          <div>SAF Fuel Controller - v0.1</div>
        </div>
      </Grid.Column>
    </Grid>
  );
}

export default UserNotFound;
