import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const UserFlagsContext = createContext(false);

export const UserFlagsProvider = ({ children }) => {
  UserFlagsProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const [serverLocalConectado, setServerLocalConectado] = useState(false);
  const [usuarioLogeado, setUsuarioLogeado] = useState(false);

  return (
    <UserFlagsContext.Provider
      value={{
        serverLocalConectado,
        setServerLocalConectado,
        usuarioLogeado,
        setUsuarioLogeado,
      }}
    >
      {children}
    </UserFlagsContext.Provider>
  );
};
