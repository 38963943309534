import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Modal, Message, Loader, Dimmer, Header } from 'semantic-ui-react';
import { formatInTimeZone } from 'date-fns-tz';

import useToken from '../../hooks/useToken';

import api from '../../api/api';

function DetalleConsumos({ visible, onClose, vehiculoId, dominio, params }) {
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setErrorMessage] = useState('');
  const [fechaDesde, setFechaDesde] = useState('');
  const [fechaHasta, setFechaHasta] = useState('');
  const [consumos, setConsumos] = useState([]);
  const [hasError, setHasError] = useState(false);

  // Traer el token de autorizacion
  const { token } = useToken();

  const fetchConsumos = () =>
    api
      .get(`/vehiculos/consumos/${vehiculoId}/`, {
        headers: { Authorization: `Token ${token}` },
        params: params,
      })
      .then((response) => {
        setConsumos(response.data);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
        setErrorMessage('Error al comunicarse con el servidor');
      })
      .finally(() => {
        setIsLoading(false);
      });

  useEffect(() => {
    if (params.desde !== '') {
      setFechaDesde(params.desde.split('-').reverse().join('-'));
    } else {
      setFechaDesde('N/D');
    }

    if (params.hasta !== '') {
      setFechaHasta(params.hasta.split('-').reverse().join('-'));
    } else {
      setFechaHasta('N/D');
    }

    setIsLoading(true);
    fetchConsumos();
  }, []);

  const cerrarModal = () => {
    setHasError(false);
    onClose();
  };

  return (
    <Modal dimmer="blurring" open={visible} size="large">
      <Dimmer active={isLoading} inverted>
        <Loader size="large">Cargando</Loader>
      </Dimmer>
      <Modal.Header>{`Detalle de consumos - Vehiculo ${
        dominio === null ? '' : dominio
      }`}</Modal.Header>
      <Modal.Content scrolling>
        <Header as="h3">
          Desde {fechaDesde} - Hasta {fechaHasta}
        </Header>
        <Table unstackable verticalAlign="middle">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Fecha</Table.HeaderCell>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>Detalle</Table.HeaderCell>
              <Table.HeaderCell>Monto</Table.HeaderCell>
              <Table.HeaderCell>Volumen</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {consumos.map((c) => (
              <Table.Row key={c.id} positive={c.tipo === 'Credito'} negative={c.tipo === 'Debito'}>
                <Table.Cell>
                  {formatInTimeZone(new Date(c.fecha), 'UTC', 'dd/MM/yyyy HH:mm:ss')}
                </Table.Cell>
                <Table.Cell>{c.tipo}</Table.Cell>
                <Table.Cell>
                  {c.tipo === 'Credito' ? c.detalle : `Despacho ${c.detalle}`}
                </Table.Cell>
                <Table.Cell>{c.tipo === 'Debito' ? `- $ ${c.monto}` : `$ ${c.monto}`}</Table.Cell>
                <Table.Cell>{c.tipo === 'Debito' ? `${c.volumen} Lts` : '-'}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Message hidden={!hasError} error header="Error" content={msg} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarModal} primary>
          Salir
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

DetalleConsumos.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vehiculoId: PropTypes.number.isRequired,
  dominio: PropTypes.string.isRequired,
  params: PropTypes.object,
};

export default DetalleConsumos;
