/* eslint-disable react/jsx-props-no-spreading */
/* eslint no-unneeded-ternary: "error" */

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Ref } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';

function FormInput({
  control,
  label,
  name,
  placeholder,
  onChangedEvent,
  validationRules,
  ...props
}) {
  // Armar un objeto con las propiedades custom que se pasan como validationRules
  // mas la propiedad de required
  const rules = { required: props.required, ...validationRules };

  return (
    <Controller
      defaultValue=""
      control={control}
      rules={rules}
      name={name ? name : label.toLowerCase()}
      render={({ field: { ref, onChange, value: selectedValue }, fieldState: { invalid } }) => (
        <Ref innerRef={ref}>
          <Form.Input
            {...props}
            label={label}
            placeholder={placeholder}
            onChange={(e, { value }) => {
              onChange(value);
              if (onChangedEvent) onChangedEvent(value);
            }}
            value={selectedValue}
            error={invalid}
          />
        </Ref>
      )}
    />
  );
}

FormInput.propTypes = {
  control: PropTypes.any.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChangedEvent: PropTypes.func,
  validationRules: PropTypes.any,
  required: PropTypes.bool,
};

export default FormInput;
