import React, { useState, useEffect } from 'react';
import { Button, Grid, Modal } from 'semantic-ui-react';
import { useLocation, useHistory } from 'react-router-dom';

import styles from './CrearUsuarioFlota.module.css';

import FormRegister from '../FormRegister/FormRegister';

function CrearUsuarioFlota() {
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [flotaId, setFlotaId] = useState(0);
  const [flotaNombre, setFlotaNombre] = useState('');
  const [codigo, setCodigo] = useState('');
  const [email, setEmail] = useState('');

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const history = useHistory();

  useEffect(() => {
    const email = query.get('id');
    const flota_id = query.get('flota_id');
    const flota_nombre = query.get('flota');
    const codigo = query.get('code');

    setFlotaId(flota_id);
    setFlotaNombre(flota_nombre);
    setEmail(email);
    setCodigo(codigo);
  }, []);

  const handleSuccess = () => {
    setRegisterSuccess(false);
    history.push('/');
  };

  return (
    <>
      {registerSuccess && (
        <Modal dimmer="blurring" open={registerSuccess} size="tiny">
          <Modal.Header>Usuario registrado!</Modal.Header>
          <Modal.Content>
            Usuario registrado correctamente y verificado correctamente!
          </Modal.Content>
          <Modal.Actions>
            <Button color="violet" onClick={handleSuccess}>
              Aceptar
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <FormRegister
            flota
            datosFlota={{ email: email, flotaId: flotaId, nombre: flotaNombre, codigo: codigo }}
            onRegisterSuccess={() => setRegisterSuccess(true)}
          />

          <div className={styles.footerRegister}>
            <div>SAF Fuel Controller - v2.1</div>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
}

export default CrearUsuarioFlota;
