import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { Card, Image, Icon, Dropdown } from 'semantic-ui-react';

import styles from './CardSurtidor.module.css';

import animationDespachando from '../../assets/lottie/despachando3.json';
import animationSolicitud from '../../assets/lottie/call.json';
import surtDisponible from '../../assets/img/surt_disponible.png';
import surtAutorizado from '../../assets/img/surt_despachando.png';
import surtDefectuoso from '../../assets/img/surt_defectuoso.png';
import surtImpago from '../../assets/img/surt_impago.png';

import useToken from '../../hooks/useToken';

const classNames = [
  null,
  styles.disponible,
  styles.solicitud,
  styles.despachando,
  styles.autorizado,
  styles.impago,
  null,
  null,
  styles.defectuoso,
  styles.defectuoso,
  styles.defectuoso,
];

// Respetar los codigos de estados que se utilizan en el posservice
const estados = [
  null,
  'Disponible',
  'Solicitud',
  'Despachando',
  'Autorizado',
  'Venta Impaga',
  null,
  null,
  'Defectuoso',
  'Anulado',
  'Detenido',
];

const imageNames = [
  null,
  surtDisponible,
  null, // Solicitud. Se utiliza animacion
  null, // DEspachando. Se utiliza animacion
  surtAutorizado,
  surtImpago,
  null,
  null,
  surtDefectuoso,
  surtDefectuoso,
  surtDefectuoso,
];

const optionDespachando = {
  loop: true,
  autoplay: true,
  animationData: animationDespachando,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const optionSolicitud = {
  loop: true,
  autoplay: true,
  animationData: animationSolicitud,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function CardSurtidor({
  surtidor,
  onClickUltimosDespachos,
  onClickCalibracion,
  onClickAutorizar,
  onClickModoAutorizacion,
}) {
  const { user } = useToken();

  return (
    <Card color={surtidor.modo_autorizacion === 0 ? 'violet' : 'green'}>
      <Card.Content
        className={styles.cardTitulo}
        content={`Surtidor ${surtidor.display_id} - ${surtidor.marca} ${surtidor.modelo}`}
      />

      <Card.Content textAlign="center">
        {/* Si no esta despachando o solicitud */}
        {surtidor.estado !== 2 && surtidor.estado !== 3 && (
          <Image src={imageNames[surtidor.estado]} size="tiny" />
        )}
        {surtidor.estado === 3 && <Lottie options={optionDespachando} height={80} width={80} />}
        {surtidor.estado === 2 && <Lottie options={optionSolicitud} height={75} width={75} />}
      </Card.Content>

      <Card.Content className={classNames[surtidor.estado]}>
        Estado: {estados[surtidor.estado]}
      </Card.Content>

      <Card.Content
        description={`Vehiculo: ${
          surtidor.vehiculo_autorizado === null
            ? '-'
            : surtidor.dominio.length > 12
            ? surtidor.dominio.substring(0, 12) + '...'
            : surtidor.dominio
        }`}
      />

      <Card.Content extra>
        <div className={styles.extraContainer}>
          <div>
            <Icon name="cogs" />
            {surtidor.aforador === null ? 'N/D' : surtidor.aforador} Lts.
          </div>
          {/* <Button icon floated="right" compact >
            <Icon name="ellipsis vertical" />
          </Button> */}
          <Dropdown inline floating direction="left">
            <Dropdown.Menu>
              <Dropdown.Item text="Autorizar" onClick={onClickAutorizar} />
              <Dropdown.Item text="Ver ultimos despachos" onClick={onClickUltimosDespachos} />
              <Dropdown.Divider />
              <Dropdown.Item
                // disabled={permiso < 2}
                disabled
                text="Calibracion"
                onClick={onClickCalibracion}
              />
              <Dropdown.Divider />
              <Dropdown.Item
                disabled={user.role < 2}
                text="Cambiar modo autorizacion"
                onClick={onClickModoAutorizacion}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Content>
    </Card>
  );
}

CardSurtidor.propTypes = {
  surtidor: PropTypes.object.isRequired,
  onClickUltimosDespachos: PropTypes.func.isRequired,
  onClickCalibracion: PropTypes.func.isRequired,
  onClickAutorizar: PropTypes.func.isRequired,
  onClickModoAutorizacion: PropTypes.func.isRequired,
};

export default CardSurtidor;
