import React, { useEffect, useState, useContext } from 'react';
import { Card } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';

import styles from './TanquesPage.module.css';
import HeaderBar from '../../components/HeaderBar/HeaderBar';

import CardTanque from '../../components/CardTanque/CardTanque';
import AjusteTanque from '../../components/AjusteTanque/AjusteTanque';
import CargaTanque from '../../components/CargaTanque/CargaTanque';
import AlertaTanque from '../../components/AlertaTanque/AlertaTanque';

import useToken from '../../hooks/useToken';

import { SedeContext } from '../../context/SedeContext';

import api from '../../api/api';

function TanquesPage() {
  const [cargaTanque, setCargaTanque] = useState(false);
  const [ajusteTanque, setAjusteTanque] = useState(false);
  const [alertaTanque, setAlertaTanque] = useState(false);
  const [tanqueEdit, setTanqueEdit] = useState([]);
  const [tanques, setTanques] = useState([]);
  const { token } = useToken();
  const { sede } = useContext(SedeContext);

  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' });
  const isMinimized = useMediaQuery({ query: '(max-width: 1000px)' });

  const fetchTanques = () =>
    api
      .get(`tanques/`, {
        headers: { Authorization: `Token ${token}` },
        params: {
          sede: sede.codigo,
        },
      })
      .then((response) => {
        setTanques(response.data);
      });

  useEffect(() => {
    fetchTanques();
    const intervalId = setInterval(fetchTanques, 5000);
    return () => clearInterval(intervalId);
  }, [sede]);

  return (
    <>
      <HeaderBar titulo="Estado de Tanques" />
      <div className={styles.pageContainer}>
        {ajusteTanque && (
          <AjusteTanque
            visible={ajusteTanque}
            onClose={() => setAjusteTanque(false)}
            onEdit={fetchTanques}
            tanqueEdit={tanqueEdit}
          />
        )}

        {cargaTanque && (
          <CargaTanque
            visible={cargaTanque}
            onClose={() => setCargaTanque(false)}
            onEdit={fetchTanques}
            tanqueEdit={tanqueEdit}
          />
        )}

        {alertaTanque && (
          <AlertaTanque
            visible={alertaTanque}
            onClose={() => setAlertaTanque(false)}
            onEdit={fetchTanques}
            tanqueEdit={tanqueEdit}
          />
        )}

        <Card.Group itemsPerRow={isMinimized ? 3 : isLaptop ? 4 : 5}>
          {tanques.map((tk) => (
            <CardTanque
              key={tk.id}
              tanque={tk}
              onAjusteClick={() => {
                setTanqueEdit(tk);
                setAjusteTanque(true);
              }}
              onCargaManualClick={() => {
                setTanqueEdit(tk);
                setCargaTanque(true);
              }}
              onAlertaClick={() => {
                setTanqueEdit(tk);
                setAlertaTanque(true);
              }}
            />
          ))}
        </Card.Group>
      </div>
    </>
  );
}

export default TanquesPage;
