import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Header, Image } from 'semantic-ui-react';

import styles from './UserVerificado.module.css';
import logo from '../../assets/img/logodrz.png';

import api from '../../api/api';

function UserVerificado() {
  const history = useHistory();
  const [verificacionExitosa, setVerificacionExitosa] = useState(false);

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const checkVerificationToken = () => {
    const id = query.get('id');
    const token = query.get('token');

    const data = {
      id: id,
      token: token,
    };

    api
      .post('auth/verificar/', data)
      .then(() => {
        setVerificacionExitosa(true);
      })
      .catch(() => {
        setVerificacionExitosa(false);
      });
  };

  useEffect(() => {
    checkVerificationToken();
  }, []);

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image src={logo} width="50%" centered />
        <Header as="h2" color="violet" textAlign="center">
          {verificacionExitosa
            ? 'Usuario verificado correctamente!'
            : 'Error al verificar el usuario. Por favor, intentelo mas tarde'}
        </Header>

        <div className={styles.footer}>
          <button
            type="button"
            className={styles.linkButton}
            onClick={() => (window.location = 'mailto:info@drz-servicios.com')}
          >
            Contactarnos
          </button>
          <button type="button" className={styles.linkButton} onClick={() => history.push('/')}>
            Volver
          </button>
        </div>

        <div className={styles.footerDrz}>
          <div>SAF Fuel Controller - v0.1</div>
        </div>
      </Grid.Column>
    </Grid>
  );
}

export default UserVerificado;
