import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Form, Grid, Header, Image, Segment, Message, Modal } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

// import axios from 'axios';

import FormInput from '../FormInput/FormInput';

import styles from './CambioPassword.module.css';
import logo from '../../assets/img/logodrz.png';

import api from '../../api/api';

function CambioPassword() {
  const history = useHistory();
  const [userId, setUserId] = useState('');
  const [passwordToken, setPasswordToken] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [msgError, setMsgError] = useState([]);
  const [cambioExitoso, setCambioExitoso] = useState(false);

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const { control, handleSubmit, watch } = useForm();

  const watchPassword = watch('password');

  const checkPasswordToken = () => {
    const id = query.get('id');
    const token = query.get('token');

    setUserId(id);
    setPasswordToken(token);

    const data = {
      id: id,
      token: token,
    };

    api.post('/auth/changepassword/verificar/', data).catch(() => {
      history.push('/tokennotfound');
    });
  };

  const aceptarCambio = () => {
    setCambioExitoso(false);
    history.push('/');
  };

  useEffect(() => {
    // Si el token es invalido, redirigir a la pagina de no encontrado
    checkPasswordToken();
  }, []);

  const submitForm = (data) => {
    setLoading(true);

    const dataToSend = {
      token: passwordToken,
      id: userId,
      password: data.password,
    };

    api
      .post('/auth/changepassword/confirmar/', dataToSend)
      .then(() => {
        setError(false);
        setCambioExitoso(true);
      })
      .catch((e) => {
        const errorData = e.response.data.error.details;
        const msgArray = [];

        errorData.forEach((m) => {
          msgArray.push(m.message);
        });

        setMsgError(msgArray);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {cambioExitoso && (
        <Modal dimmer="blurring" open={cambioExitoso} size="tiny">
          <Modal.Header>Cambio exitoso!</Modal.Header>
          <Modal.Content>
            Contraseña actualizada correctamente. Inicie sesión con su nueva contraseña
          </Modal.Content>
          <Modal.Actions>
            <Button color="violet" onClick={aceptarCambio}>
              Aceptar
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Image src={logo} width="50%" centered />
          <Header as="h2" color="violet" textAlign="center">
            Cambiar contraseña
          </Header>
          <Form onSubmit={handleSubmit(submitForm)} id="formPassword" size="large" noValidate>
            <Segment>
              <FormInput
                required
                name="password"
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                control={control}
              />

              <FormInput
                required
                name="confirm_password"
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Confirmar Password"
                type="password"
                control={control}
                validationRules={{
                  validate: (value) => value === watchPassword,
                }}
              />

              <Button type="submit" color="violet" fluid size="large" loading={isLoading}>
                Cambiar contraseña
              </Button>
            </Segment>
          </Form>

          <Message error hidden={!isError}>
            <div className={styles.msgError}>
              {msgError.map((msg, index) => (
                <Message.Item key={index}>{msg}</Message.Item>
              ))}
            </div>
          </Message>

          <div className={styles.footerRegister}>
            <div>SAF Fuel Controller - v0.1</div>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
}

export default CambioPassword;
