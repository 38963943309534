import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

import FormInput from '../FormInput/FormInput';

import useToken from '../../hooks/useToken';

import api from '../../api/api';

function AjusteTanque({ visible, onClose, onEdit, tanqueEdit }) {
  const [msg, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Traer el token de autorizacion
  const { token } = useToken();

  const { control, handleSubmit } = useForm();

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const submitForm = (data) => {
    setIsLoading(true);

    const mov = {
      fecha: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      tanque: tanqueEdit.id,
      codigo_sede: tanqueEdit.codigo_sede,
      movimiento: parseFloat(parseFloat(data.ajuste).toFixed(2)),
      tipo: 'AJUSTE',
      contenido_inicial: tanqueEdit.contenido,
      contenido_final: parseFloat(parseFloat(data.ajuste).toFixed(2)),
    };

    api
      .post(`tanques/movimientos/`, mov, {
        headers: { Authorization: `Token ${token}` },
        params: {
          sede: tanqueEdit.codigo_sede,
        },
      })
      .then(() => {
        onEdit();
        setHasError(false);
        cerrarFormulario();
      })
      .catch((error) => {
        setHasError(true);
        console.log(error.response);
        setErrorMessage('Error al comunicarse con el servidor');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal dimmer="blurring" open={visible} size="mini">
      <Modal.Header>{`Ajuste de contenido en TK ${
        tanqueEdit === null ? '' : tanqueEdit.display_id
      }`}</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={handleSubmit(submitForm)}
          id="formAjusteTanque"
          noValidate
          loading={isLoading}
        >
          <FormInput
            required
            name="ajuste"
            fluid
            label="Nuevo contenido"
            placeholder="0.00 Lts"
            control={control}
            validationRules={{ pattern: /^[0-9.]*$/ }}
          />
        </Form>
        <Message hidden={!hasError} error header="Error" content={msg} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formAjusteTanque" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

AjusteTanque.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  tanqueEdit: PropTypes.object,
};

export default AjusteTanque;
