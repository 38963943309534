import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';

import useToken from '../../hooks/useToken';

import api from '../../api/api';

function CalibracionSurtidor({ visible, onClose, onEdit, surtidor }) {
  const [msg, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Traer el token de autorizacion
  const { token } = useToken();

  const { control, handleSubmit } = useForm();

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const submitForm = (data) => {
    setIsLoading(true);

    api
      .post(`surtidores/calibracion/`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        onEdit();
        setHasError(false);
        cerrarFormulario();
      })
      .catch((error) => {
        setHasError(true);
        console.log(error.response);
        setErrorMessage('Error al comunicarse con el servidor');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal dimmer="blurring" open={visible} size="tiny">
      <Modal.Header>{`Calibracion de surtidor ${
        surtidor === null ? '' : surtidor.display_id
      }`}</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={handleSubmit(submitForm)}
          id="formCalibracionSurtidor"
          noValidate
          loading={isLoading}
        >
          <Form.Group widths="equal">
            <FormInput
              required
              name="calibracion"
              fluid
              label="Coeficiente"
              placeholder="0.001123"
              control={control}
              validationRules={{ pattern: /^[0-9.]*$/ }}
            />
          </Form.Group>
        </Form>
        <Message hidden={!hasError} error header="Error" content={msg} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formCalibracionSurtidor" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

CalibracionSurtidor.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  surtidor: PropTypes.object,
};

export default CalibracionSurtidor;
