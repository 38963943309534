import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';

import { SedeProvider } from './context/SedeContext';
import { UserFlagsProvider } from './context/UserFlagContext';

// CSS de semantic ui
import 'semantic-ui-css/semantic.min.css';

ReactDOM.render(
  <BrowserRouter>
    <SedeProvider>
      <UserFlagsProvider>
        <HomePage />
      </UserFlagsProvider>
    </SedeProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
