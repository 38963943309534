import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Message, List } from 'semantic-ui-react';
import { formatInTimeZone } from 'date-fns-tz';

import useToken from '../../hooks/useToken';
import api from '../../api/api';

import { SedeContext } from '../../context/SedeContext';

function VisualizarCierre({ visible, onClose, turnoSeleccionado }) {
  const [turnoVacio, setTurnoVacio] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [listaDespachos, setListaDespachos] = useState([]);
  const [totalPorVolumen, setTotalPorVolumen] = useState([]);
  const [totalPorMonto, setTotalPorMonto] = useState([]);
  const [totalPorSurtidor, setTotalPorSurtidor] = useState([]);
  const [totalPorProducto, setTotalPorProducto] = useState([]);

  const { token } = useToken();

  // Traer el context
  const { sede } = useContext(SedeContext);

  const fetchCierre = () => {
    const params = {
      desde: turnoSeleccionado.abierto,
      hasta: turnoSeleccionado.cerrado,
      surtidores: -1,
      productos: -1,
      sede: sede.codigo,
    };

    api
      .get(`despachos/`, {
        headers: { Authorization: `Token ${token}` },
        params: params,
      })
      .then((response) => {
        setListaDespachos(response.data);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  useEffect(() => {
    if (listaDespachos.length > 0) {
      setTurnoVacio(false);

      const totalVolumen = listaDespachos.map((d) => d.volumen).reduce((sum, d) => sum + d);
      const totalMonto = listaDespachos.map((d) => d.monto).reduce((sum, d) => sum + d);

      const despachosPorSurtidor = [];
      const despachosPorProducto = [];

      listaDespachos.reduce((acc, value) => {
        if (!acc[value.producto]) {
          acc[value.producto] = { producto: value.producto, total: 0 };
          despachosPorProducto.push(acc[value.producto]);
        }

        acc[value.producto].total += value.volumen;
        return acc;
      }, {});

      listaDespachos.reduce((acc, value) => {
        if (!acc[value.surtidor_id]) {
          acc[value.surtidor_id] = { surtidor: value.surtidor_id, total: 0 };
          despachosPorSurtidor.push(acc[value.surtidor_id]);
        }
        acc[value.surtidor_id].total += value.volumen;
        return acc;
      }, {});

      setTotalPorVolumen(totalVolumen);
      setTotalPorMonto(totalMonto);
      setTotalPorProducto(despachosPorProducto);
      setTotalPorSurtidor(despachosPorSurtidor);
    } else {
      setTurnoVacio(true);
    }
  }, [listaDespachos]);

  useEffect(() => {
    fetchCierre();
  }, [turnoSeleccionado]);

  return (
    <Modal dimmer="blurring" open={visible} size="tiny">
      <Modal.Header>Resumen de cierre - Turno #{turnoSeleccionado.id}</Modal.Header>

      <Modal.Content>
        {hasError && (
          <Message error header="Error" content="Error al actualizar los detalles del despacho" />
        )}

        {turnoVacio && (
          <Message
            error
            header="Turno Vacio"
            content="El turno seleccionado no posee ningun registro"
          />
        )}

        {!hasError && !turnoVacio && (
          <Message
            positive
            content={`Desde ${formatInTimeZone(
              new Date(turnoSeleccionado.abierto),
              'UTC',
              'dd/MM/yyyy HH:mm:ss',
            )} - hasta ${formatInTimeZone(
              new Date(turnoSeleccionado.cerrado),
              'UTC',
              'dd/MM/yyyy HH:mm:ss',
            )}`}
          />
        )}

        {!turnoVacio && (
          <List relaxed>
            <List.Item>
              <List.Content>
                <List.Header>Totales de turno</List.Header>
                <List.Description>
                  <List.List>
                    <List.Item>
                      Total por monto
                      <List.Content floated="right">
                        <b>{parseFloat(totalPorMonto).toFixed(2)} $</b>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      Total por volumen
                      <List.Content floated="right">
                        <b>{parseFloat(totalPorVolumen).toFixed(2)} $</b>
                      </List.Content>
                    </List.Item>
                  </List.List>
                </List.Description>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <List.Header>Totales por producto</List.Header>
                <List.Description>
                  <List.List>
                    {totalPorProducto.map((t, index) => (
                      <List.Item key={index}>
                        {t.producto}
                        <List.Content floated="right">
                          <b>{parseFloat(t.total).toFixed(2)} Lts.</b>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List.List>
                </List.Description>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <List.Header>Totales por surtidor</List.Header>
                <List.Description>
                  <List.List>
                    {totalPorSurtidor.map((t, index) => (
                      <List.Item key={index}>
                        {t.surtidor}
                        <List.Content floated="right">
                          <b>{parseFloat(t.total).toFixed(2)} Lts.</b>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List.List>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button primary onClick={onClose}>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

VisualizarCierre.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  turnoSeleccionado: PropTypes.object.isRequired,
};

export default VisualizarCierre;
