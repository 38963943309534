import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Modal, Message } from 'semantic-ui-react';
import { formatInTimeZone } from 'date-fns-tz';

import useToken from '../../hooks/useToken';

// import { SedeContext } from '../../context/SedeContext';

import api from '../../api/api';

function UltimosDespachos({ visible, onClose, surtidor }) {
  const [msg, setErrorMessage] = useState('');
  const [despachos, setDespachos] = useState([]);
  const [hasError, setHasError] = useState(false);
  // const { sede } = useContext(SedeContext);

  // Traer el token de autorizacion
  const { token } = useToken();

  const fetchSurtidores = () =>
    api
      .get(`despachos/last/${surtidor.id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        setDespachos(response.data);
        setHasError(false);
      })
      .catch((error) => {
        setHasError(true);
        console.log(error.response);
        setErrorMessage('Error al comunicarse con el servidor');
      });

  useEffect(() => {
    fetchSurtidores();
  }, []);

  const cerrarModal = () => {
    setHasError(false);
    onClose();
  };

  return (
    <Modal dimmer="blurring" open={visible} size="large">
      <Modal.Header>{`Ultimos despachos - Surtidor ${
        surtidor === null ? '' : surtidor.display_id
      }`}</Modal.Header>
      <Modal.Content>
        <Table unstackable verticalAlign="middle">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Fecha</Table.HeaderCell>
              <Table.HeaderCell>Vehiculo</Table.HeaderCell>
              <Table.HeaderCell>Interno</Table.HeaderCell>
              <Table.HeaderCell>Producto</Table.HeaderCell>
              <Table.HeaderCell>Detalle</Table.HeaderCell>
              <Table.HeaderCell>Monto</Table.HeaderCell>
              <Table.HeaderCell>PPU</Table.HeaderCell>
              <Table.HeaderCell>Volumen</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {despachos.map((despacho) => (
              <Table.Row key={despacho.id}>
                <Table.Cell collapsing>{despacho.id}</Table.Cell>
                <Table.Cell collapsing>
                  {formatInTimeZone(new Date(despacho.fecha), 'UTC', 'dd/MM/yyyy HH:mm:ss')}
                </Table.Cell>
                <Table.Cell collapsing>
                  {despacho.vehiculo ? despacho.vehiculo.dominio : ''}
                </Table.Cell>
                <Table.Cell collapsing>
                  {despacho.vehiculo ? despacho.vehiculo.interno : 'N/D'}
                </Table.Cell>
                <Table.Cell>{despacho.producto}</Table.Cell>
                <Table.Cell>{despacho.detalle}</Table.Cell>
                <Table.Cell>$ {despacho.monto}</Table.Cell>
                <Table.Cell>$ {despacho.ppu}</Table.Cell>
                <Table.Cell>{despacho.volumen} Lts. </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Message hidden={!hasError} error header="Error" content={msg} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarModal} primary>
          Salir
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

UltimosDespachos.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  surtidor: PropTypes.object.isRequired,
};

export default UltimosDespachos;
