import React, { useState, useEffect } from 'react';
import { Grid, Form, Button, Label, Message, Modal, Icon } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import styles from './PreciosPage.module.css';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import FormInput from '../../components/FormInput/FormInput';

import useToken from '../../hooks/useToken';

import api from '../../api/api';

function PreciosPage() {
  const [isWarning, setIsWarning] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [modal, setModal] = useState(false);
  const [mensajeModal, setMensajeModal] = useState('');
  const [productos, setProductos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { token } = useToken();

  const { control, handleSubmit, setValue } = useForm();

  const fetchProductos = () =>
    api.get(`productos/`, { headers: { Authorization: `Token ${token}` } }).then((response) => {
      setProductos(response.data);
    });

  useEffect(() => fetchProductos(), []);

  useEffect(() => {
    productos.forEach((p) => setValue(`${p.id}`, p.ppu));
  }, [productos]);

  const mensajeCambio = () => {
    fetchProductos();
    setMensajeModal('Precios modificados correctamente');
    setModal(true);
    setIsLoading(false);
  };

  const submitForm = (data) => {
    setIsLoading(true);

    const dataCambioPrecio = [];

    productos.forEach((p) => {
      if (p.ppu !== parseFloat(data[p.id])) {
        dataCambioPrecio.push({
          producto_id: p.id,
          ppu: parseFloat(data[p.id]),
        });
      }
    });

    if (dataCambioPrecio.length > 0) {
      setIsWarning(false);
      api
        .post(`productos/precios/`, dataCambioPrecio, {
          headers: { Authorization: `Token ${token}` },
        })
        .then(() => {
          setHasError(false);
        })
        .catch(() => {
          setHasError(true);
          setModal(true);
          setMensajeModal('Error al comunicarse con el servidor');
        })
        .finally(() => {
          setTimeout(() => mensajeCambio(), 1500);
        });
    } else {
      setMensajeModal('El precio establecido coincide con el precio actual');
      setModal(true);
      setIsLoading(false);
      setIsWarning(true);
    }
  };

  return (
    <>
      <Modal dimmer="blurring" centered={false} open={modal} size="tiny">
        <Modal.Header>{hasError ? 'Error' : 'Atencion'}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Message error={hasError} success={!isWarning && !hasError} warning={isWarning} icon>
              {isWarning && <Icon name="warning sign" />}
              {hasError && <Icon name="error sign" />}
              {!hasError && !isWarning && <Icon name="check circle" />}
              {mensajeModal}
            </Message>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => setModal(false)}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>

      <HeaderBar titulo="Ajuste de precios" />
      <div className={styles.pageContainer}>
        <div className={styles.tableContainer}>
          <Form autoComplete="off" onSubmit={handleSubmit(submitForm)}>
            <Grid stackable columns={4} verticalAlign="middle">
              {productos.map((p, index) => (
                <Grid.Row key={index}>
                  <Grid.Column width={2}>
                    <b>{p.nombre}</b>
                  </Grid.Column>
                  <Grid.Column>
                    <FormInput
                      icon="dollar"
                      fluid
                      placeholder="0.000"
                      name={`${p.id}`}
                      control={control}
                      validationRules={{ pattern: /^[0-9.]*$/ }}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Label>{p.descripcion}</Label>
                  </Grid.Column>
                </Grid.Row>
              ))}

              <Grid.Row>
                <Grid.Column>
                  <Button loading={isLoading} primary>
                    Cambiar Precios
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </div>
      </div>
    </>
  );
}

export default PreciosPage;
