import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Message } from 'semantic-ui-react';

function DetalleMovimiento({ visible, onClose, movimiento }) {
  const cerrarFormulario = () => {
    onClose();
  };

  return (
    <Modal dimmer="blurring" open={visible} size="tiny">
      <Modal.Header>
        {`Detalle de movimiento en tanque ${movimiento.tanque_display_id}`}
      </Modal.Header>
      <Modal.Content>
        <Message color="violet">
          <Message.Header>{movimiento.detalle}</Message.Header>
          <p>
            Generado por: <b>{movimiento.creado_por}</b>
          </p>
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario} primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

DetalleMovimiento.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  movimiento: PropTypes.object.isRequired,
};

export default DetalleMovimiento;
