import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Header, Image, Segment, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';

import logo from '../../assets/img/logodrz.png';

import api from '../../api/api';

import { SedeContext } from '../../context/SedeContext';
import { UserFlagsContext } from '../../context/UserFlagContext';
import useToken from '../../hooks/useToken';

function FormLogin() {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [msgError, setMsgError] = useState([]);

  const { control, handleSubmit, setValue } = useForm();

  const { setUser } = useToken();

  const { setSede, setListaSedes } = useContext(SedeContext);
  const { setUsuarioLogeado, setServerLocalConectado } = useContext(UserFlagsContext);

  const submitForm = (data) => {
    setLoading(true);

    api
      .post('auth/token/', data)
      .then((r) => {
        if (!Object.hasOwn(r.data, 'role')) {
          setError(true);
          setMsgError(
            'Usuario inhabilitado para el ingreso al sistema web. Consulte con el administrador (ERR 1638)',
          );

          // Reiniciar el formulario
          setValue('username', '');
          setValue('password', '');

          return;
        }

        // Establecer el estado del cliente (API TW)
        setServerLocalConectado(r.data.cliente_conectado);
        setUsuarioLogeado(true);

        // Establecer el token de autenticacion y el permiso
        const user = {
          token: r.data.token,
          username: data.username,
          role: r.data.role,
        };

        setUser(user);

        // Setear la lista de sedes disponibles en el context y configurar la primera como default
        setListaSedes(r.data.sedes);
        setSede(r.data.sedes[0]);

        setError(false);

        if (user.role === 1) history.push('/vehiculos');
        else history.push('/playa');
      })
      .catch(() => {
        // Mostrar mensaje de error
        setMsgError('Error al iniciar sesión. Revise sus credenciales e intentelo nuevamente');
        setError(true);

        // Reiniciar el formulario
        setValue('username', '');
        setValue('password', '');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Image src={logo} width="50%" centered />
      <Header as="h2" color="violet" textAlign="center">
        Iniciar sesion
      </Header>
      <Form onSubmit={handleSubmit(submitForm)} id="formLogin" size="large" noValidate>
        <Segment>
          <FormInput
            required
            name="username"
            fluid
            icon="user"
            iconPosition="left"
            placeholder="Usuario"
            control={control}
          />

          <FormInput
            required
            name="password"
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            control={control}
          />

          <Button type="submit" color="violet" fluid size="large" loading={isLoading}>
            Iniciar Sesion
          </Button>
        </Segment>
      </Form>

      <Message error hidden={!isError}>
        {msgError}
      </Message>
    </>
  );
}

export default FormLogin;
