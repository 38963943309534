import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

import FormInput from '../FormInput/FormInput';
import FormSelect from '../FormSelect/FormSelect';

import useToken from '../../hooks/useToken';

import api from '../../api/api';

const options = [
  { key: '1', value: 'CARGA', text: 'CARGA' },
  { key: '2', value: 'DESCARGA', text: 'DESCARGA' },
];

function CargaTanque({ visible, onClose, onEdit, tanqueEdit }) {
  const [msg, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Traer el token de autorizacion
  const { token } = useToken();

  const { control, handleSubmit } = useForm();

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const submitForm = (data) => {
    setIsLoading(true);

    const mov = {
      fecha: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      tanque: tanqueEdit.id,
      codigo_sede: tanqueEdit.codigo_sede,
      movimiento: parseFloat(parseFloat(data.movimiento).toFixed(2)),
      detalle: data.detalle === null ? '' : data.detalle,
      tipo: data.tipo,
      contenido_inicial: tanqueEdit.contenido,
      contenido_final:
        data.tipo === 'DESCARGA'
          ? parseFloat((tanqueEdit.contenido - parseFloat(data.movimiento)).toFixed(2))
          : parseFloat((tanqueEdit.contenido + parseFloat(data.movimiento)).toFixed(2)),
    };

    console.log(mov);

    api
      .post(`tanques/movimientos/`, mov, {
        headers: { Authorization: `Token ${token}` },
        params: { sede: tanqueEdit.codigo_sede },
      })
      .then(() => {
        onEdit();
        setHasError(false);
        cerrarFormulario();
      })
      .catch(() => {
        setHasError(true);
        setErrorMessage('Error al comunicarse con el servidor');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal dimmer="blurring" open={visible} size="tiny">
      <Modal.Header>{`Movimiento manual en TK ${
        tanqueEdit === null ? '' : tanqueEdit.display_id
      }`}</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={handleSubmit(submitForm)}
          id="formCargaTanque"
          noValidate
          loading={isLoading}
        >
          <Form.Group widths="equal">
            <FormSelect
              required
              label="Tipo"
              options={options}
              placeholder="Seleccione"
              control={control}
            />
            <FormInput
              required
              name="movimiento"
              fluid
              label="Volumen"
              placeholder="0.00 Lts"
              control={control}
              validationRules={{ pattern: /^[0-9.]*$/ }}
            />
          </Form.Group>
          <FormInput name="detalle" fluid label="Detalle" placeholder="Detalle" control={control} />
        </Form>
        <Message hidden={!hasError} error header="Error" content={msg} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formCargaTanque" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

CargaTanque.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  tanqueEdit: PropTypes.object.isRequired,
};

export default CargaTanque;
