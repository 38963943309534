import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { Menu, Icon, Label } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import styles from './SidebarMenu.module.css';
import logo from '../../assets/img/logodrz.png';
import ModalAlertasTanques from '../../components/ModalAlertasTanques/ModalAlertasTanques';

import useToken from '../../hooks/useToken';
import api from '../../api/api';

const menuItemsClientes = [
  {
    key: 0,
    seccion: 'playa',
    nombre: 'playa',
    label: 'Estado de playa',
    icon: 'industry',
    permiso_requerido: 2,
  },
  {
    key: 1,
    seccion: 'playa',
    nombre: 'turnos',
    label: 'Turnos',
    icon: 'calendar alternate outline',
    permiso_requerido: 2,
  },
  {
    key: 2,
    seccion: 'playa',
    nombre: 'despachos',
    label: 'Historial de despachos',
    icon: 'history',
    permiso_requerido: 2,
  },
  {
    key: 3,
    seccion: 'playa',
    nombre: 'precios',
    label: 'Ajuste de precios',
    icon: 'dollar',
    permiso_requerido: 2,
  },
  {
    key: 4,
    seccion: 'tanques',
    nombre: 'tanques',
    label: 'Tanques',
    icon: 'tint',
    permiso_requerido: 2,
  },
  {
    key: 5,
    seccion: 'tanques',
    nombre: 'movimientos',
    label: 'Movimientos',
    icon: 'exchange',
    permiso_requerido: 2,
  },
  {
    key: 6,
    seccion: 'vehiculos',
    nombre: 'vehiculos',
    label: 'Vehiculos',
    icon: 'truck',
    permiso_requerido: 1,
  },
  {
    key: 7,
    seccion: 'vehiculos',
    nombre: 'consumos',
    label: 'Consumos',
    icon: 'chart bar',
    permiso_requerido: 1,
  },
  {
    key: 10,
    seccion: 'vehiculos',
    nombre: 'flotas',
    label: 'Flotas',
    icon: 'shipping fast',
    permiso_requerido: 2,
  },
  {
    key: 11,
    seccion: 'vehiculos',
    nombre: 'playeros',
    label: 'Playeros',
    icon: 'user',
    permiso_requerido: 2,
  },
  // {
  //   key: 8,
  //   seccion: 'vehiculos',
  //   nombre: 'cargas',
  //   label: 'Estado de cargas',
  //   icon: 'sync alternate',
  //   permiso_requerido: 1,
  // },
  // {
  //   key: 9,
  //   seccion: 'vehiculos',
  //   nombre: 'desbloqueos',
  //   label: 'Desbloqueos',
  //   icon: 'key',
  //   permiso_requerido: 2,
  // },
];

function SidebarMenu() {
  const history = useHistory();
  const [activeItem, setActiveItem] = useState('');
  const [alerta, setAlerta] = useState(false);
  const { user, token } = useToken();

  useEffect(() => {
    api
      .get(`/tanques/alertas`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((r) => {
        setAlerta(r.data.alerta);
      });
  }, []);

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    history.push(`/${name}`);
  };

  const SeccionSidebarMenu = ({ titulo, menuItems }) => (
    <>
      <div className={styles.sidebarHeader}>{titulo.toUpperCase()}</div>
      {menuItems
        .filter((item) => item.seccion === titulo)
        .map((item) => (
          <Menu.Item
            key={item.key}
            name={item.nombre}
            active={activeItem === item.nombre}
            // Permitir solo ciertas opciones de acuerdo al usuario
            disabled={item.permiso_requerido > user.role}
            onClick={handleItemClick}
            color="violet"
          >
            <Icon name={item.icon} className={styles.sidebarIcon} />
            {item.label}
          </Menu.Item>
        ))}
    </>
  );

  return (
    <div className={styles.sidebar}>
      <ModalAlertasTanques visible={alerta} onClose={() => setAlerta(false)} />

      <div className={styles.sidebarTop}>
        <div className="d-flex justify-content-left g-0 mt-2">
          <Image src={logo} fluid width="70%" />
        </div>
        <Menu text vertical fluid>
          <SeccionSidebarMenu titulo="playa" menuItems={menuItemsClientes} />
          <SeccionSidebarMenu titulo="tanques" menuItems={menuItemsClientes} />
          <SeccionSidebarMenu titulo="vehiculos" menuItems={menuItemsClientes} />
        </Menu>
      </div>
      <div className={styles.sidebarLabel}>
        <Label horizontal>
          <Icon name="code" />
          v2.2
        </Label>
      </div>
    </div>
  );
}

SidebarMenu.propTypes = {
  titulo: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.object),
};

export default SidebarMenu;
