import { useState, useEffect } from 'react';

export default function useTableData() {
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataToShow, setDataToShow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRegisters, setTotalRegisters] = useState(1);

  const dToShow = () => {
    const d = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
    setDataToShow(d);
  };

  useEffect(() => {
    // Calcular la cantidad de paginas y de registros
    const total = Math.ceil(data.length / rowsPerPage);
    const len = data.length;

    // Setear la cantidad total de paginas
    setTotalPages(total);

    // Setear el total de registros
    setTotalRegisters(len);

    // Setear los datos a mostrar
    dToShow();
  }, [data, rowsPerPage]);

  useEffect(() => {
    dToShow();
  }, [currentPage]);

  return {
    rawData,
    data,
    setData,
    setRawData,
    setRowsPerPage,
    setCurrentPage,
    currentPage,
    totalPages,
    dataToShow,
    totalRegisters,
  };
}
