import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormSelect from '../FormSelect/FormSelect';
import FormCheck from '../FormCheck/FormCheck';
import FormInput from '../FormInput/FormInput';

import useToken from '../../hooks/useToken';

import { SedeContext } from '../../context/SedeContext';

import api from '../../api/api';

function ConfirmarAutorizacion({ visible, onClose, surtidor }) {
  const [msg, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState([]);
  const [precintosAutorizados, setPrecintosAutorizados] = useState(false);
  const [precintosDesbloqueados, setPrecintosDesbloqueados] = useState(false);
  const [listaVehiculos, setListaVehiculos] = useState([]);
  const [asignarVehiculo, setAsignarVehiculo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [precinto1, setPrecinto1] = useState('');
  const [precinto2, setPrecinto2] = useState('');
  const [contPrecintoInvalido, setContPrecintoInvalido] = useState(2);

  const { sede } = useContext(SedeContext);

  // Traer el token de autorizacion
  const { token, permiso } = useToken();

  const { control, handleSubmit } = useForm();

  const fetchVehiculos = () => {
    api
      .get('vehiculos/', { headers: { Authorization: `Token ${token}` } })
      .then((response) => {
        const vehiculosData = response.data;
        const vehiculosOptions = [];
        vehiculosData.forEach((v) => {
          vehiculosOptions.push({
            key: v.id,
            text: `${v.dominio} - INT ${v.interno}`,
            value: v,
          });
          setListaVehiculos(vehiculosOptions);
          setHasError(false);
        });
      })
      .catch(() => {
        setHasError(true);
      });
  };

  useEffect(() => {
    fetchVehiculos();

    // Bloquear la posibilidad de no asignar vehiculo si es un usuario con pocos privilegios
    if (permiso < 2) {
      setAsignarVehiculo(true);
    }
  }, []);

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const validarPrecintos = () => {
    setIsLoading(true);

    const dataAutorizacion = {
      vehiculo: vehiculoSeleccionado.id,
      precinto1: precinto1,
      precinto2: precinto2,
    };

    api
      .post(`vehiculos/autorizacion/${vehiculoSeleccionado.id}/`, dataAutorizacion, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        setHasError(false);
        setPrecintosAutorizados(true);
        setPrecintosDesbloqueados(false);
      })
      .catch((error) => {
        console.log(error);
        setHasError(true);

        if (error.response.status === 401) {
          setErrorMessage(`${error.response.data}. Intentos restantes: ${contPrecintoInvalido}`);
          setContPrecintoInvalido(contPrecintoInvalido - 1);
        } else {
          setErrorMessage('Error al comunicarse con el servidor');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (contPrecintoInvalido === -1) {
      setHasError(false);
      setPrecintosAutorizados(true);
      setPrecintosDesbloqueados(true);
    }
  }, [contPrecintoInvalido]);

  const handleVehiculoChange = (v) => {
    setVehiculoSeleccionado(v);
    setPrecintosAutorizados(false);
    setPrecintosDesbloqueados(false);
  };

  const submitForm = (data) => {
    setIsLoading(true);

    const dataAutorizacion = {
      sede: sede.codigo,
      vehiculo: vehiculoSeleccionado.id,
      desbloqueado: precintosDesbloqueados,
      precinto1: data.nuevo_precinto1,
      precinto2: data.nuevo_precinto2,
      autorizacion_pendiente: true,
    };

    api
      .patch(`surtidores/${surtidor.id}/`, dataAutorizacion, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        setHasError(false);
        cerrarFormulario();
      })
      .catch((error) => {
        setHasError(true);
        setPrecintosAutorizados(false);
        setPrecintosDesbloqueados(false);
        setContPrecintoInvalido(3);

        if (error.response.status === 401) {
          setErrorMessage(error.response.data);
        } else {
          setErrorMessage('Error al comunicarse con el servidor');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const submitFormAutorizarPrecintos = (data) => {
  //   setIsLoading(true);

  //   const dataAutorizacion = {
  //     vehiculo: vehiculoSeleccionado.id,
  //     precinto1: data.precinto1,
  //     precinto2: data.precinto2,
  //   };

  //   api
  //     .post(`vehiculos/autorizacion/${vehiculoSeleccionado.id}/`, dataAutorizacion, {
  //       headers: { Authorization: `Token ${token}` },
  //     })
  //     .then(() => {
  //       setHasError(false);
  //       setPrecintosAutorizados(true);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setHasError(true);

  //       if (error.response.status === 401) {
  //         setErrorMessage(error.response.data);
  //       } else {
  //         setErrorMessage('Error al comunicarse con el servidor');
  //       }
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  return (
    <Modal dimmer="blurring" open={visible} size="tiny">
      <Modal.Header>¿Seguro que desea autorizar el surtidor #{surtidor.display_id}?</Modal.Header>
      <Modal.Content>
        <Form id="formAutorizar" noValidate loading={isLoading} onSubmit={handleSubmit(submitForm)}>
          <FormCheck
            fluid
            label="Asignar vehiculo"
            control={control}
            disabled={permiso < 2}
            onSelectionChanged={(check) => setAsignarVehiculo(check)}
          />
          <FormSelect
            name="vehiculo"
            disabled={!asignarVehiculo}
            placeholder="Dominio/Interno"
            fluid
            search
            control={control}
            selection
            options={listaVehiculos}
            onSelectionChanged={(v) => handleVehiculoChange(v)}
          />
          {vehiculoSeleccionado.precinto_habilitado && (
            <>
              <Form.Group widths="equal">
                <FormInput
                  label="Precinto 1"
                  name="precinto1"
                  required
                  fluid
                  placeholder="Precinto 1"
                  disabled={precintosAutorizados}
                  control={control}
                  onChangedEvent={(p1) => {
                    setPrecinto1(p1);
                  }}
                />
                <FormInput
                  label="Precinto 2"
                  name="precinto2"
                  required
                  fluid
                  placeholder="Precinto 2"
                  disabled={precintosAutorizados}
                  control={control}
                  onChangedEvent={(p2) => {
                    setPrecinto2(p2);
                  }}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <FormInput
                  label="Nuevo Precinto 1"
                  name="nuevo_precinto1"
                  required
                  disabled={!precintosAutorizados}
                  fluid
                  placeholder="Nuevo Precinto 1"
                  control={control}
                />
                <FormInput
                  label="Nuevo Precinto 2"
                  name="nuevo_precinto2"
                  required
                  disabled={!precintosAutorizados}
                  fluid
                  placeholder="Nuevo Precinto 2"
                  control={control}
                />
              </Form.Group>
            </>
          )}
        </Form>
        <Message hidden={!precintosAutorizados || precintosDesbloqueados} success>
          <Message.Header>Precintos validados correctamente!</Message.Header>
          <Message.Content>Ingrese los nuevos precintos para autorizar</Message.Content>
        </Message>

        <Message hidden={!precintosDesbloqueados} warning>
          <Message.Header>Precintos desbloqueados</Message.Header>
          <Message.Content>Ingrese los nuevos precintos para autorizar</Message.Content>
        </Message>

        <Message hidden={!hasError} error header="Error" content={msg} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        {vehiculoSeleccionado.precinto_habilitado && !precintosAutorizados && (
          <Button onClick={validarPrecintos} color="teal">
            Validar Precintos
          </Button>
        )}

        {(!vehiculoSeleccionado.precinto_habilitado || precintosAutorizados) && (
          <Button form="formAutorizar" primary>
            Autorizar
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

ConfirmarAutorizacion.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  surtidor: PropTypes.object.isRequired,
};

export default ConfirmarAutorizacion;
