import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Dropdown, Ref } from 'semantic-ui-react';
import LiquidFillGauge from 'react-liquid-gauge';
import { useMediaQuery } from 'react-responsive';

import styles from './CardTanque.module.css';

const coloresCombustible = ['#5ba5eb', '#6feb5b', '#f5e342', '#e65beb', '#9e9b9b', '#9e9b9b'];

function CardTanque({ tanque, onAjusteClick, onCargaManualClick, onAlertaClick }) {
  const [dim, setDim] = useState(0);
  const [alarmaVisible, setAlarmaVisible] = useState(false);
  const ref = useRef(null);

  useLayoutEffect(() => {
    setDim(ref.current.clientWidth);
  });

  setTimeout(() => {
    setAlarmaVisible(!alarmaVisible);
  }, 500);

  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' });
  // const isMinimized = useMediaQuery({ query: '(max-width: 1000px)' });

  return (
    <Card>
      <Card.Content>
        <div className={styles.cardTitulo}>
          {`Tanque ${tanque.display_id} - ${tanque.producto.nombre}`}
          <Icon
            disabled={!tanque.alerta_activa}
            name={tanque.alerta_activa ? 'bell outline' : 'bell slash outline'}
            color={tanque.alerta_activa ? 'black' : 'gray'}
          />
        </div>
      </Card.Content>
      <Ref innerRef={ref}>
        <Card.Content textAlign="center">
          <LiquidFillGauge
            style={{ margin: '0 auto' }}
            width={isLaptop ? dim * 0.5 : dim * 0.6}
            height={isLaptop ? dim * 0.5 : dim * 0.6}
            value={(tanque.contenido / tanque.capacidad) * 100}
            percent="%"
            textSize={1}
            textOffsetX={0}
            textOffsetY={0}
            waveAnimation
            waveFrequency={1}
            waveAmplitude={4}
            circleStyle={{
              fill: coloresCombustible[tanque.producto.id - 1],
            }}
            waveStyle={{
              fill: coloresCombustible[tanque.producto.id - 1],
            }}
            textStyle={{
              fill: coloresCombustible[tanque.producto.id - 1],
              fontFamily: 'Arial',
            }}
            waveTextStyle={{
              fill: '#ffffff',
              fontFamily: 'Arial',
            }}
          />
        </Card.Content>
      </Ref>
      <Card.Content>
        {tanque.alerta_activa && tanque.contenido < tanque.contenido_minimo && (
          <div className={styles.contenidoContainer + ' ' + styles.contenidoAlerta}>
            <div>Contenido:</div>
            <div>
              {alarmaVisible && <Icon name="warning sign" color="red" />}
              {tanque.contenido} Lts
            </div>
          </div>
        )}

        {(!tanque.alerta_activa || tanque.contenido >= tanque.contenido_minimo) && (
          <div className={styles.contenidoContainer}>
            <div>Contenido: </div>
            <div>{tanque.contenido} Lts</div>
          </div>
        )}
      </Card.Content>
      <Card.Content extra>
        <div className={styles.extraContainer}>
          <div>Capacidad:</div>
          <div>{tanque.capacidad} Lts</div>
        </div>
      </Card.Content>
      <Card.Content extra>
        <div className={styles.extraContainer}>
          <div>
            <Icon name="cogs" />
            Opciones
          </div>
          <Dropdown inline floating direction="left">
            <Dropdown.Menu>
              <Dropdown.Item text="Ajustar contenido" onClick={onAjusteClick} />
              <Dropdown.Item text="Movimiento manual" onClick={onCargaManualClick} />
              <Dropdown.Divider />
              <Dropdown.Item text="Inicio de descarga" />
              <Dropdown.Divider />
              <Dropdown.Item text="Alarmas" onClick={onAlertaClick} />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Content>
    </Card>
  );
}

CardTanque.propTypes = {
  tanque: PropTypes.object.isRequired,
  onAjusteClick: PropTypes.func.isRequired,
  onCargaManualClick: PropTypes.func.isRequired,
  onAlertaClick: PropTypes.func.isRequired,
};

export default CardTanque;
