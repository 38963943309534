/* eslint-disable no-param-reassign */
import axios from 'axios';

const serverDir = fetch('./api.json')
  .then((r) => r.json())
  .then((j) => j.server);

async function getBaseUrl() {
  const server = await serverDir;
  const base = `${server}/api/`;
  return base;
}

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = await getBaseUrl();
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
