import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const getUser = () => {
    const tokenString = localStorage.getItem('token');
    const userString = localStorage.getItem('username');
    const roleString = localStorage.getItem('role');

    const username = JSON.parse(userString);
    const userToken = JSON.parse(tokenString);
    const role = JSON.parse(roleString);

    const user = {
      token: userToken,
      username: username,
      role: role,
    };

    return user;
  };

  const [token] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const saveUser = (user) => {
    localStorage.setItem('token', JSON.stringify(user.token));
    localStorage.setItem('username', JSON.stringify(user.username));
    localStorage.setItem('role', JSON.stringify(user.role));

    setUser(user);
  };

  return {
    setUser: saveUser,
    token,
    user,
    getUser,
    getToken,
  };
}
