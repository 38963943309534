import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';
import FormCheck from '../FormCheck/FormCheck';

import useToken from '../../hooks/useToken';

import api from '../../api/api';

function AlertaTanque({ visible, onClose, onEdit, tanqueEdit }) {
  const [msg, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [alerta, setAlerta] = useState(false);
  const [alertaEmail, setAlertaEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { token } = useToken();

  const { control, handleSubmit, setValue } = useForm();

  useEffect(() => {
    setValue('alerta', tanqueEdit.alerta_activa);
    setValue('alerta_email', tanqueEdit.alerta_email);
    setValue('contenido_minimo', tanqueEdit.contenido_minimo);

    setAlerta(tanqueEdit.alerta_activa);
    setAlertaEmail(tanqueEdit.alerta_email);
  }, [visible]);

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const submitForm = (data) => {
    setIsLoading(true);

    const alerta = {
      alerta_activa: data.alerta,
      alerta_email: data.alerta_email,
      contenido_minimo:
        data.contenido_minimo === '' ? 0 : parseFloat(parseFloat(data.contenido_minimo).toFixed(2)),
    };

    api
      .patch(`tanques/${tanqueEdit.id}/`, alerta, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        onEdit();
        setHasError(false);
        cerrarFormulario();
      })
      .catch(() => {
        setHasError(true);
        setErrorMessage('Error al comunicarse con el servidor');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal dimmer="blurring" open={visible} size="mini">
      <Modal.Header>{`Configuracion de alarma en Tanque ${
        tanqueEdit === null ? '' : tanqueEdit.display_id
      }`}</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={handleSubmit(submitForm)}
          id="formAlertaTanque"
          noValidate
          loading={isLoading}
        >
          <Form.Group fluid>
            <FormCheck
              name="alerta"
              label="Alarma Activa"
              control={control}
              onSelectionChanged={(check) => setAlerta(check)}
            />

            <FormCheck
              disabled={!alerta}
              name="alerta_email"
              label="Alarma por email"
              control={control}
              onSelectionChanged={(check) => setAlertaEmail(check)}
            />
          </Form.Group>

          <Message icon info hidden={!alertaEmail}>
            Las alertas de contenido minimo se enviaran al mail asociado a la cuenta
          </Message>

          <FormInput
            required={alerta}
            name="contenido_minimo"
            fluid
            label="Contenido Minimo"
            placeholder="0.00 Lts"
            disabled={!alerta}
            control={control}
            validationRules={{ pattern: /^[0-9.]*$/ }}
          />
        </Form>
        <Message hidden={!hasError} error header="Error" content={msg} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formAlertaTanque" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

AlertaTanque.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  tanqueEdit: PropTypes.object,
};

export default AlertaTanque;
