import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Header, Button, Modal, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';
import FormCheck from '../FormCheck/FormCheck';
import FormSelect from '../FormSelect/FormSelect';

import styles from './NuevoVehiculo.module.css';

import useToken from '../../hooks/useToken';

import api from '../../api/api';

const options = [
  { key: 'y', text: 'SI', value: 1 },
  { key: 'n', text: 'NO', value: 0 },
];

const modoTagOptions = [
  { key: 0, text: 'DEFAULT', value: 0 },
  { key: 1, text: 'HEX', value: 1 },
  { key: 2, text: 'HEX Invertido', value: 2 },
];

function NuevoVehiculo({ visible, onClose, onEdit, vehiculoEdit, productos, flotas }) {
  const [msg, setErrorMessage] = useState('');
  const [tag, setTag] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [multiproducto, setMultiProducto] = useState(true);
  const [modoTag, setModoTag] = useState(0);

  // Traer el token de autorizacion
  const { token } = useToken();

  // Si no hay id, establecer el flag indicando que es un nuevo vehiculo
  const isNuevoVehiculo = !vehiculoEdit;

  const { control, handleSubmit, setValue } = useForm();

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const handleTagOptionsChange = (e, { value }) => {
    setModoTag(value);

    if (value === 0) {
      // Setear el tag original
      setValue('tag_uid', tag);
    } else if (value === 1) {
      // Setear el tag convertido a hexa
      const taghex = parseInt(tag, 10).toString(16);
      setValue('tag_uid', taghex);
    } else if (value === 2) {
      const taghex = parseInt(tag, 10).toString(16);

      const taghexrev = taghex
        .match(/[a-fA-F0-9]{2}/g)
        .reverse()
        .join('');
      setValue('tag_uid', taghexrev);
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const submitForm = (data) => {
    // Traer la data del formulario y armar el json para el post
    const vehiculoNuevoData = {
      ...data,
      dominio: data.dominio.toUpperCase(),
      interno: parseInt(data.interno, 10),
      flota: data.flota === 0 ? null : data.flota,
      producto_habilitado: data.multiproducto === 1 ? 1 : data.producto_habilitado,
      saldo: data.saldo === '' ? 0 : parseFloat(data.saldo),
      cant_cargas: 0,
      alerta_tablero: false,
      precinto_habilitado: false,
      precinto1: '',
      precinto2: '',
      habilitado: true,
    };

    const vehiculoEditData = {
      ...data,
      dominio: data.dominio.toUpperCase(),
      interno: parseInt(data.interno, 10),
      flota: data.flota === 0 ? null : data.flota,
      producto_habilitado: data.multiproducto === 1 ? 1 : data.producto_habilitado,
      saldo: data.saldo === '' ? 0 : parseFloat(data.saldo),
    };

    setIsLoading(true);

    if (isNuevoVehiculo) {
      api
        .post('vehiculos/', vehiculoNuevoData, { headers: { Authorization: `Token ${token}` } })
        .then(() => {
          onEdit();
          setHasError(false);
          cerrarFormulario();
        })
        .catch((error) => {
          setHasError(true);
          console.log(error.response);

          if (error.response.status === 409) {
            setErrorMessage(error.response.data);
          } else {
            setErrorMessage('Error al comunicarse con el servidor');
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      api
        .patch(`vehiculos/${vehiculoEdit.id}/`, vehiculoEditData, {
          headers: { Authorization: `Token ${token}` },
        })
        .then(() => {
          onEdit();
          setHasError(false);
          cerrarFormulario();
        })
        .catch((error) => {
          setHasError(true);

          if (error.response.status === 409) {
            setErrorMessage(error.response.data);
          } else {
            setErrorMessage('Error al comunicarse con el servidor');
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // Si se esta editando, prepopular el form
  useEffect(() => {
    setModoTag(0);
    if (!isNuevoVehiculo) {
      setValue('dominio', vehiculoEdit.dominio);
      setValue('interno', vehiculoEdit.interno === null ? '' : vehiculoEdit.interno);
      setValue('marca', vehiculoEdit.marca);
      setValue('modelo', vehiculoEdit.modelo);
      setValue('tag_uid', vehiculoEdit.tag_uid);
      setValue('multiproducto', vehiculoEdit.multiproducto ? 1 : 0);
      setValue('vuelta_cero', vehiculoEdit.vuelta_cero);
      setValue('reinicio_saldo', vehiculoEdit.reinicio_saldo);
      setValue('permite_saldo_negativo', vehiculoEdit.permite_saldo_negativo);
      setValue('saldo', vehiculoEdit.saldo);
      setValue('flota', vehiculoEdit.flota === null ? 0 : vehiculoEdit.flota);

      setMultiProducto(vehiculoEdit.multiproducto ? 1 : 0);
      if (!vehiculoEdit.multiproducto) {
        setValue('producto_habilitado', vehiculoEdit.producto_habilitado);
      }
    } else {
      setValue('flota', 0);
    }
  }, [vehiculoEdit]);

  return (
    <Modal dimmer="blurring" open={visible} size="small">
      <Modal.Header>
        {isNuevoVehiculo
          ? 'Nuevo Vehiculo'
          : `Editar Vehiculo #${vehiculoEdit.id} - ${vehiculoEdit.dominio}`}
      </Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={handleSubmit(submitForm)}
          onKeyDown={(e) => checkKeyDown(e)}
          id="formVehiculo"
          noValidate
          loading={isLoading}
        >
          <Form.Group widths="equal">
            <FormInput
              required
              fluid
              label="Dominio"
              placeholder="Dominio"
              control={control}
              validationRules={{ pattern: /^[a-zA-Z0-9 ]*$/ }}
            />
            <FormInput fluid label="Interno" placeholder="Interno" control={control} />
          </Form.Group>

          <Form.Group widths="equal">
            <FormInput fluid label="Marca" placeholder="Marca" control={control} />
            <FormInput fluid label="Modelo" placeholder="Modelo" control={control} />
          </Form.Group>

          <Header as="h4">Sistema de flotas</Header>

          <Form.Group widths="equal">
            <FormInput
              label="Tag"
              name="tag_uid"
              required
              placeholder="AB001122"
              control={control}
              value={tag}
              onChangedEvent={(v) => setTag(v)}
            />

            {/* Elementos no controlados en el form */}
            <Form.Select
              label="Modo"
              options={modoTagOptions}
              value={modoTag}
              onChange={handleTagOptionsChange}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <FormSelect
              label="Flota"
              name="flota"
              placeholder="Seleccione"
              control={control}
              options={flotas}
            />
          </Form.Group>

          <Header as="h4">Combustibles</Header>

          <Form.Group widths="equal">
            <FormSelect
              required
              label="Multiproducto"
              options={options}
              placeholder="Seleccione"
              onSelectionChanged={(mp) => setMultiProducto(mp)}
              control={control}
            />

            <FormSelect
              required={multiproducto === 0}
              disabled={multiproducto === 1}
              name="producto_habilitado"
              label="Producto"
              options={productos}
              placeholder="Seleccione"
              control={control}
            />
          </Form.Group>

          <Header as="h4" className={styles.sidebarHeader}>
            Saldos
          </Header>

          <Form.Group widths="equal">
            <FormInput icon="dollar sign" label="Saldo" placeholder="1500.0" control={control} />
          </Form.Group>

          <Form.Group widths="equal">
            <FormCheck
              name="permite_saldo_negativo"
              control={control}
              label="Permite saldo negativo"
            />
            <FormCheck name="vuelta_cero" control={control} label="Vuelta a cero" />
            <FormCheck name="reinicio_saldo" control={control} label="Reinicio saldo" />
          </Form.Group>
        </Form>
        <Message hidden={!hasError} error header="Error" content={msg} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formVehiculo" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

NuevoVehiculo.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  vehiculoEdit: PropTypes.object.isRequired,
  productos: PropTypes.arrayOf(PropTypes.string),
  flotas: PropTypes.arrayOf(PropTypes.string),
};

export default NuevoVehiculo;
