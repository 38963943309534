import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';
import FormSelect from '../FormSelect/FormSelect';

import useToken from '../../hooks/useToken';
import api from '../../api/api';

const options = [
  { key: 0, text: 'ESTANDAR', value: 0 },
  { key: 1, text: 'PRUEBA SURT.', value: 1 },
  { key: 2, text: 'PRUEBA SURT. RET.', value: 2 },
];

function EditarDespacho({ visible, onClose, despacho, onEdit }) {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit, setValue } = useForm();

  const { token } = useToken();

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const submitForm = (data) => {
    setIsLoading(true);

    api
      .patch(`/despachos/${despacho.id}/`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        onEdit(data);
        setHasError(false);
        cerrarFormulario();
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => setIsLoading(false));
  };

  // Si se esta editando, prepopular el form
  useEffect(() => {
    setValue('detalle', despacho.detalle === null ? '' : despacho.detalle);
    setValue('tipo', despacho.tipo);
  });

  return (
    <Modal dimmer="blurring" open={visible} size="tiny">
      <Modal.Header>Editar Despacho - #{despacho.id}</Modal.Header>
      <Modal.Content>
        <Form
          loading={isLoading}
          onSubmit={handleSubmit(submitForm)}
          id="formEditarDespacho"
          noValidate
          error={hasError}
        >
          <Message error header="Error" content="Error al actualizar los detalles del despacho" />
          <Form.Group widths="equal">
            <FormInput
              fluid
              label="Detalle"
              placeholder="Detalle"
              control={control}
              validationRules={{ pattern: /^[a-zA-Z0-9 ]*$/ }}
            />
            <FormSelect
              required
              label="Tipo"
              options={options}
              placeholder="Seleccione"
              control={control}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formEditarDespacho" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

EditarDespacho.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  despacho: PropTypes.object.isRequired,
};

export default EditarDespacho;
