import React from 'react';
import { Grid, Form, Button } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import styles from './PreferenciasPage.module.css';
import HeaderBar from '../../components/HeaderBar/HeaderBar';

import FormSelect from '../../components/FormSelect/FormSelect';

const options = [
  { key: 0, value: 'DOMINIO', text: 'Dominio' },
  { key: 1, value: 'INTERNO', text: 'Interno' },
  { key: 2, value: 'AMBOS', text: 'Dominio/Interno' },
];

const optionsFlotas = [
  { key: 0, value: 'FLOTAS', text: 'Flotas' },
  { key: 1, value: 'VEHICULOS', text: 'Vehiculos individuales' },
];

function PreferenciasPage() {
  const { control } = useForm();

  const handleChange = (v) => {
    console.log(v);
  };

  return (
    <>
      <HeaderBar titulo="Preferencias" />
      <div className={styles.pageContainer}>
        <div className={styles.tableContainer}>
          <Form autoComplete="off">
            <Grid stackable columns={4} verticalAlign="middle">
              <Grid.Row>
                <Grid.Column>
                  <FormSelect
                    name="ads"
                    required
                    label="Identificacion de vehiculos"
                    options={options}
                    placeholder="Seleccione"
                    onSelectionChanged={(v) => handleChange(v)}
                    control={control}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <FormSelect
                    required
                    label="Modo flotas"
                    options={optionsFlotas}
                    placeholder="Seleccione"
                    onSelectionChanged={(v) => handleChange(v)}
                    control={control}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Button primary>Aplicar</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </div>
      </div>
    </>
  );
}

export default PreferenciasPage;
