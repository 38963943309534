import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Header, Image, Segment, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';

import logo from '../../assets/img/logodrz.png';

import api from '../../api/api';

function FormRegister({ onRegisterSuccess, flota, datosFlota }) {
  const [isLoading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [errorMsg, setErrorMsg] = useState([]);
  const [registrarUsuario, setRegistrarUsuario] = useState(false);

  const { control, handleSubmit, reset, watch } = useForm();

  const watchPassword = watch('password');

  const submitForm = (data) => {
    setLoading(true);

    if (!flota) {
      api
        .post('auth/register/', data)
        .then(() => {
          setHasError(false);
          onRegisterSuccess();
          setRegistrarUsuario(false);
        })
        .catch((e) => {
          setHasError(true);
          if (e.response.status === 409) {
            setErrorMsg(e.response.data);
          } else {
            setErrorMsg('Error al intentar registrar un nuevo usuario');
          }
        })
        .finally(() => {
          setLoading(false);

          // Reiniciar el formulario
          reset({
            username: '',
            password: '',
            confirm_password: '',
            email: '',
            codigo: '',
          });
        });
    } else {
      const dataFlota = {
        email: datosFlota.email,
        username: data.username,
        password: data.password,
        codigo: datosFlota.codigo,
        flotaId: datosFlota.flotaId,
      };

      api
        .post('auth/register/flota/', dataFlota)
        .then(() => {
          setHasError(false);
          onRegisterSuccess();
          setRegistrarUsuario(false);
        })
        .catch((e) => {
          setHasError(true);
          if (e.response.status === 409) {
            setErrorMsg(e.response.data);
          } else {
            setErrorMsg('Error al intentar registrar un nuevo usuario');
          }
        })
        .finally(() => {
          setLoading(false);

          // Reiniciar el formulario
          reset({
            username: '',
            password: '',
            confirm_password: '',
            email: '',
            codigo: '',
          });
        });
    }
  };

  return (
    <>
      <Image src={logo} width="50%" centered />
      <Header as="h2" color="violet" textAlign="center">
        {flota ? `Nuevo usuario para flota ${datosFlota.nombre}` : 'Nuevo Usuario'}
      </Header>
      <Form onSubmit={handleSubmit(submitForm)} id="formRegister" size="large" noValidate>
        <Segment>
          {!flota && (
            <FormInput
              required
              name="codigo"
              fluid
              icon="key"
              iconPosition="left"
              placeholder="Codigo identificador"
              control={control}
            />
          )}

          {!flota && (
            <FormInput
              required
              name="email"
              fluid
              icon="envelope"
              iconPosition="left"
              placeholder="Correo electronico"
              control={control}
              // Validacion de email
              validationRules={{ pattern: /^.+@.+\..+$/ }}
            />
          )}

          <FormInput
            required
            name="username"
            fluid
            icon="user"
            iconPosition="left"
            placeholder="Usuario"
            control={control}
          />

          <FormInput
            required
            name="password"
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            control={control}
          />

          <FormInput
            required
            name="confirm_password"
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Confirmar Password"
            type="password"
            control={control}
            validationRules={{
              validate: (value) => value === watchPassword || (!registrarUsuario && !flota),
            }}
          />

          <Button type="submit" color="violet" fluid size="large" loading={isLoading}>
            Registrar
          </Button>
        </Segment>
      </Form>

      <Message error hidden={!hasError}>
        {errorMsg}
      </Message>
    </>
  );
}

FormRegister.propTypes = {
  onRegisterSuccess: PropTypes.func.isRequired,
  flota: PropTypes.bool,
  datosFlota: PropTypes.object,
};

export default FormRegister;
