import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormSelect from '../FormSelect/FormSelect';

import useToken from '../../hooks/useToken';
import api from '../../api/api';

function AsignarVehiculo({ visible, onClose, vehiculo, onEdit }) {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listaVehiculos, setListaVehiculos] = useState(false);
  // const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState(false);
  const { control, handleSubmit } = useForm();

  const { token } = useToken();

  const cerrarFormulario = () => {
    setHasError(false);
    onClose();
  };

  const fetchVehiculos = () => {
    api
      .get('vehiculos/', { headers: { Authorization: `Token ${token}` } })
      .then((response) => {
        const vehiculosData = response.data;
        const vehiculosOptions = [];
        vehiculosData.forEach((v) => {
          vehiculosOptions.push({
            key: v.id,
            text: `${v.dominio} - INT ${v.interno}`,
            value: v,
          });
          setListaVehiculos(vehiculosOptions);
          setHasError(false);
        });
      })
      .catch(() => {
        setHasError(true);
      });
  };

  const submitForm = (data) => {
    setIsLoading(true);

    api
      .patch(`/despachos/${vehiculo.despacho_id}/`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        onEdit(data);
        setHasError(false);
        cerrarFormulario();
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => setIsLoading(false));
  };

  // Si se esta editando, prepopular el form
  useEffect(() => {
    fetchVehiculos();
  }, [vehiculo]);

  return (
    <Modal dimmer="blurring" open={visible} size="tiny">
      <Modal.Header>Asignar Vehiculo - Despacho #{vehiculo.despacho_id}</Modal.Header>
      <Modal.Content>
        <Form
          loading={isLoading}
          onSubmit={handleSubmit(submitForm)}
          id="formAsignarVehiculo"
          noValidate
          error={hasError}
        >
          <Message error header="Error" content="Error al actualizar los detalles del despacho" />
          <FormSelect
            name="vehiculo"
            placeholder="Dominio/Interno"
            fluid
            search
            control={control}
            selection
            options={listaVehiculos}
            // onSelectionChanged={(v) => handleVehiculoChange(v)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cerrarFormulario}>Cancelar</Button>
        <Button form="formAsignarVehiculo" primary>
          Aceptar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

AsignarVehiculo.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  vehiculo: PropTypes.object,
  onEdit: PropTypes.func,
};

export default AsignarVehiculo;
