import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Container, Form, Message, Button, TransitionablePortal } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';

import FormInput from '../FormInput/FormInput';
import styles from './ModalEmailCambioPassword.module.css';

import api from '../../api/api';

function ModalEmailCambioPassword({ visible, onClose }) {
  const [isEmailLoading, setEmailLoading] = useState(false);
  const [emailEnviado, setEmailEnviado] = useState(false);
  const [isErrorMail, setErrorMail] = useState(false);
  const [msgErrorMail, setMsgErrorMail] = useState([]);

  const {
    control: emailControl,
    handleSubmit: handleSubmitEmail,
    setValue: setValueEmail,
  } = useForm();

  const submitFormEmail = (data) => {
    setEmailLoading(true);

    api
      .post('auth/changepassword/', data)
      .then(() => {
        setErrorMail(false);
        setValueEmail('email', '');
        setEmailEnviado(true);
        setTimeout(onClose, 5000);
      })
      .catch((e) => {
        const errorData = e.response.data.error.details;
        const msgArrayMail = [];

        errorData.forEach((m) => {
          msgArrayMail.push(m.message);
        });

        setMsgErrorMail(msgArrayMail);
        setErrorMail(true);
      })
      .finally(() => {
        setEmailLoading(false);
      });
  };

  useEffect(() => {
    setErrorMail(false);
    setValueEmail('');
  }, [visible]);

  return (
    <TransitionablePortal open={visible} transition={{ animation: 'scale', duration: 300 }}>
      <Modal dimmer="blurring" open size="tiny">
        <Modal.Header>Cambio de contraseña</Modal.Header>
        <Modal.Content>
          Ingrese el correo electrónico con el que se encuentra registrado para solicitar un cambio
          de contraseña
          <Container className={styles.containerEmail}>
            <Form
              onSubmit={handleSubmitEmail(submitFormEmail)}
              id="formEmail"
              size="large"
              noValidate
              loading={isEmailLoading}
            >
              <FormInput
                required
                name="email"
                fluid
                icon="envelope"
                iconPosition="left"
                placeholder="Correo electronico"
                control={emailControl}
              />
            </Form>
          </Container>
          <Message error hidden={!isErrorMail}>
            <div className={styles.msgError}>
              {msgErrorMail.map((msg, index) => (
                <Message.Item key={index}>{msg}</Message.Item>
              ))}
            </div>
          </Message>
          <Message success hidden={!emailEnviado}>
            El correo electronico se envió correctamente. Compruebe su bandeja de entrada para
            proceder con el cambio
          </Message>
        </Modal.Content>
        <Modal.Actions>
          {!emailEnviado && (
            <>
              <button type="button" className={styles.linkButton} onClick={onClose}>
                Salir
              </button>
              <Button form="formEmail" color="violet" type="submit">
                Aceptar
              </Button>
            </>
          )}

          {emailEnviado && (
            <Button color="violet" onClick={onClose}>
              Salir
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    </TransitionablePortal>
  );
}

ModalEmailCambioPassword.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalEmailCambioPassword;
