import React, { useState, useEffect } from 'react';
import { Table, Icon, Grid, Button, Pagination, Input, Confirm, Label } from 'semantic-ui-react';

import styles from './FlotasPage.module.css';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import ModalError from '../../components/ModalError/ModalError';
import NuevaFlota from '../../components/NuevaFlota/NuevaFlota';

import useToken from '../../hooks/useToken';
import useTableData from '../../hooks/useTableData';

import api from '../../api/api';

function FlotasPage() {
  const [nombreFlota, setNombreFlota] = useState('');
  // const [isLoading, setIsLoading] = useState(false);
  const [editarFlota, setEditarFlota] = useState(false);
  const [nuevaFlota, setNuevaFlota] = useState(false);
  const [eliminarFlota, setEliminarFlota] = useState(false);

  const [flotaEdit, setFlotaEdit] = useState([]);
  const [hasError, setHasError] = useState(false);
  const { token } = useToken();

  // Traer el hook de tablas
  const {
    setData,
    setRawData,
    setCurrentPage,
    dataToShow,
    currentPage,
    totalPages,
    rawData,
    data,
  } = useTableData();

  const fetchFlotas = () => {
    api
      .get('flotas/', { headers: { Authorization: `Token ${token}` } })
      .then((response) => {
        setRawData(response.data);
        setData(response.data);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  useEffect(() => {
    fetchFlotas();
  }, []);

  const doFilter = (filtro) => {
    if (filtro !== '') {
      const fd = rawData
        // Filtrar
        .filter((d) => d.nombre.toLowerCase().indexOf(filtro.toLowerCase()) > -1);

      // Establecer la data
      setData(fd);

      // Ir a la primer pagina
      setCurrentPage(1);
    } else {
      setData(rawData);
    }
  };

  const handleFlotaEdit = (p) => {
    if (nuevaFlota) {
      fetchFlotas();
      return;
    }

    let updated = rawData.map((item) => {
      if (item.id === flotaEdit.id) {
        return { ...item, nombre: p.nombre, tag_uid: p.tag_uid };
      }
      return item;
    });

    // Actualizar el dataset completo de referencia
    setRawData(updated);

    // Actualizar el dataset que se esta mostrando
    updated = data.map((item) => {
      if (item.id === flotaEdit.id) {
        return { ...item, nombre: p.nombre, tag_uid: p.tag_uid };
      }
      return item;
    });
    setData(updated);
  };

  const handleConfirm = () => {
    // setIsLoading(true);
    api
      .delete(`flotas/${flotaEdit.id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        setHasError(false);
        fetchFlotas();
        setEliminarFlota(false);
      })
      .catch(() => {
        setHasError(true);
      });
    // .finally(() => {
    //   setIsLoading(false);
    // });
  };

  const handleSearch = (e, { value }) => {
    setNombreFlota(value);
    doFilter(value);
  };

  const toggleHabilitacion = (f) => {
    const json = {
      id: f.id,
      habilitado: !f.habilitado,
    };

    api
      .patch(`flotas/${f.id}/`, json, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        setHasError(false);
        fetchFlotas();
      })
      .catch(() => {
        setHasError(true);
      });
  };

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  return (
    <>
      <HeaderBar titulo="Registro de flotas" />
      <div className={styles.pageContainer}>
        {eliminarFlota && (
          <Confirm
            open={eliminarFlota}
            onCancel={() => setEliminarFlota(false)}
            onConfirm={handleConfirm}
            header={`Eliminar flota - #${flotaEdit.id}`}
            content="¿Seguro que desea eliminar la flota de vehiculos seleccionada?"
            confirmButton="Eliminar"
            cancelButton="Cancelar"
            size="tiny"
          />
        )}

        <ModalError visible={hasError} onClose={() => setHasError(false)} />

        {editarFlota && (
          <NuevaFlota
            visible={editarFlota}
            onClose={() => setEditarFlota(false)}
            flota={flotaEdit}
            onEdit={handleFlotaEdit}
          />
        )}

        {nuevaFlota && (
          <NuevaFlota
            visible={nuevaFlota}
            onClose={() => setNuevaFlota(false)}
            flota={null}
            onEdit={handleFlotaEdit}
          />
        )}

        <div className={styles.tableContainer}>
          <Grid stackable columns={4}>
            <Grid.Row>
              <Grid.Column>
                <Input
                  fluid
                  icon="search"
                  placeholder="Buscar playero..."
                  value={nombreFlota}
                  onChange={handleSearch}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Table unstackable selectable verticalAlign="middle">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>ID</Table.HeaderCell>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Habilitado</Table.HeaderCell>
                <Table.HeaderCell>Saldo Actual</Table.HeaderCell>
                <Table.HeaderCell>Opciones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {dataToShow.map((flota) => (
                <Table.Row key={flota.id}>
                  <Table.Cell>{flota.id}</Table.Cell>
                  <Table.Cell collapsing>{flota.nombre}</Table.Cell>
                  <Table.Cell>
                    {flota.habilitado ? (
                      <Label
                        color="teal"
                        onClick={() => toggleHabilitacion(flota)}
                        style={{ cursor: 'pointer' }}
                      >
                        SI
                      </Label>
                    ) : (
                      <Label
                        color="red"
                        onClick={() => toggleHabilitacion(flota)}
                        style={{ cursor: 'pointer' }}
                      >
                        NO
                      </Label>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {flota.saldo > 0 ? (
                      <Label color="green" basic>
                        $ {flota.saldo}
                      </Label>
                    ) : (
                      <Label color="red" basic>
                        -$ {flota.saldo * -1}
                      </Label>
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      circular
                      icon="edit outline"
                      size="tiny"
                      onClick={() => {
                        setEditarFlota(true);
                        setFlotaEdit(flota);
                      }}
                    />
                    <Button
                      circular
                      icon="trash alternate"
                      size="tiny"
                      onClick={() => {
                        setEliminarFlota(true);
                        setFlotaEdit(flota);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="10">
                  <div className={styles.tableFooter}>
                    <Button
                      floated="left"
                      icon
                      labelPosition="left"
                      primary
                      size="small"
                      onClick={() => setNuevaFlota(true)}
                    >
                      <Icon name="user add" />
                      Nueva Flota
                    </Button>
                    {totalPages > 1 && (
                      <Pagination
                        activePage={currentPage}
                        boundaryRange={1}
                        size="mini"
                        siblingRange={1}
                        totalPages={totalPages}
                        onPageChange={handlePaginationChange}
                      />
                    )}
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </div>
    </>
  );
}

export default FlotasPage;
