import React, { useState } from 'react';
import { Icon, Table, Input, Grid, Form, Button, Label, Pagination } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import { parse } from 'date-fns';

import styles from './ConsumosPage.module.css';
import HeaderBar from '../../components/HeaderBar/HeaderBar';

import ModalError from '../../components/ModalError/ModalError';
import DetalleConsumos from '../../components/DetalleConsumos/DetalleConsumos';

import api from '../../api/api';

import useToken from '../../hooks/useToken';
import useTableData from '../../hooks/useTableData';

function ConsumosPage() {
  const [queryParams, setQueryParams] = useState([]);
  const [detalleConsumos, setDetalleConsumos] = useState(false);
  const [vehiculoId, setVehiculoId] = useState('');
  const [dominio, setDominio] = useState('');
  const [dominioRef, setDominioRef] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fechaDesde, setFechaDesde] = useState('');
  const [fechaHasta, setFechaHasta] = useState('');

  // Traer el token de autenticacion
  const { token } = useToken();

  // Traer el hook de tablas
  const {
    setData,
    setRawData,
    setCurrentPage,
    dataToShow,
    rawData,
    currentPage,
    totalRegisters,
    totalPages,
  } = useTableData();

  const handleChange = (e, { name, value }) => {
    if (name === 'desde') {
      setFechaDesde(value);
    } else {
      setFechaHasta(value);
    }
  };

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const doFilter = (filtro) => {
    // Si hay algo para filtrar
    if (filtro !== '') {
      const fd = rawData
        // Remover los valores con vehiculos nulos
        .filter((d) => d.dominio)
        // Filtrar
        .filter((d) => d.dominio.toLowerCase().indexOf(filtro.toLowerCase()) > -1);

      // Establecer la data
      setData(fd);
      // Ir a la primer pagina
      setCurrentPage(1);
    } else {
      setData(rawData);
    }
  };

  const handleSearch = (e, { value }) => {
    setDominio(value);
    doFilter(value);
  };

  const handleConsulta = () => {
    let params;

    if (fechaDesde !== '' && fechaHasta !== '') {
      const fd = parse(fechaDesde, 'dd-MM-yyyy HH:mm', new Date());
      const fh = parse(fechaHasta, 'dd-MM-yyyy HH:mm', new Date());

      // Convertir a ISO sin tener en cuenta la TZ
      const fdtz = new Date(fd.getTime() - fd.getTimezoneOffset() * 60 * 1000);
      const fhtz = new Date(fh.getTime() - fh.getTimezoneOffset() * 60 * 1000);

      let fdesde = fdtz.toISOString();
      let fhasta = fhtz.toISOString();

      params = {
        desde: fdesde,
        hasta: fhasta,
      };
    } else {
      params = {
        desde: '',
        hasta: '',
      };
    }

    setQueryParams(params);
    setIsLoading(true);

    api
      .get('vehiculos/consumos/', {
        headers: { Authorization: `Token ${token}` },
        params: params,
      })
      .then((response) => {
        setRawData(response.data);
        setData(response.data);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <HeaderBar titulo="Consumos por vehiculo" />
      <div className={styles.pageContainer}>
        <ModalError visible={hasError} onClose={() => setHasError(false)} />
        {detalleConsumos && (
          <DetalleConsumos
            visible={detalleConsumos}
            onClose={() => setDetalleConsumos(false)}
            vehiculoId={vehiculoId}
            dominio={dominioRef}
            params={queryParams}
          />
        )}
        <div className={styles.tableContainer}>
          <Form autoComplete="off" noValidate>
            <Grid stackable columns={6}>
              <div className={styles.subtitle}>Filtros</div>
              <Grid.Row>
                <Grid.Column>
                  <DateInput
                    autoComplete="off"
                    closable
                    clearable
                    clearIcon={<Icon name="remove" color="red" />}
                    name="desde"
                    placeholder="Desde"
                    onChange={handleChange}
                    value={fechaDesde}
                  />
                </Grid.Column>
                <Grid.Column>
                  <DateInput
                    autoComplete="off"
                    closable
                    clearable
                    clearIcon={<Icon name="remove" color="red" />}
                    name="hasta"
                    placeholder="Hasta"
                    onChange={handleChange}
                    value={fechaHasta}
                    minDate={fechaDesde}
                  />
                </Grid.Column>

                <Grid.Column>
                  <Button
                    primary
                    icon
                    labelPosition="left"
                    loading={isLoading}
                    onClick={handleConsulta}
                  >
                    <Icon name="search" />
                    Consultar
                  </Button>
                </Grid.Column>
              </Grid.Row>

              <div className={styles.subtitle}>Busqueda</div>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input
                    fluid
                    icon="search"
                    placeholder="Buscar domino..."
                    value={dominio}
                    onChange={handleSearch}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>

          <Table unstackable selectable verticalAlign="middle">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Dominio</Table.HeaderCell>
                <Table.HeaderCell>Interno</Table.HeaderCell>
                <Table.HeaderCell>Total Monto</Table.HeaderCell>
                <Table.HeaderCell>Total Volumen</Table.HeaderCell>
                <Table.HeaderCell>Opciones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {dataToShow.map((consumo, index) => (
                <Table.Row key={consumo.dominio === null ? '1' : consumo.dominio}>
                  <Table.Cell collapsing>{index}</Table.Cell>
                  <Table.Cell>
                    {consumo.dominio === null ? 'SIN VEHICULO' : consumo.dominio}
                  </Table.Cell>
                  <Table.Cell>{consumo.interno}</Table.Cell>
                  <Table.Cell>$ {consumo.total_monto}</Table.Cell>
                  <Table.Cell>{consumo.total_volumen} Lts</Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      icon
                      size="tiny"
                      onClick={() => {
                        if (consumo.dominio === null) return;
                        setVehiculoId(consumo.vehiculo_id);
                        setDominioRef(consumo.dominio);
                        setDetalleConsumos(true);
                      }}
                    >
                      <Icon name="eye" />
                      Ver detalle de consumos
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="7">
                  <div className={styles.tableFooter}>
                    <Label>Cantidad de registros: {totalRegisters}</Label>
                    {totalPages > 1 && (
                      <Pagination
                        activePage={currentPage}
                        boundaryRange={1}
                        size="mini"
                        siblingRange={1}
                        totalPages={totalPages}
                        onPageChange={handlePaginationChange}
                      />
                    )}
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </div>
    </>
  );
}

export default ConsumosPage;
