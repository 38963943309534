import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Message, Icon } from 'semantic-ui-react';

import styles from './ModalError.module.css';

function ModalError({ visible, onClose }) {
  return (
    <Modal dimmer="blurring" open={visible} onClose={onClose} size="tiny">
      <Modal.Header className={styles.error}>Error</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Message error icon>
            <Icon name="warning sign" />
            Hubo un error al conectarse con el servidor de despachos. Intentelo nuevamente mas tarde
          </Message>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ModalError.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalError;
