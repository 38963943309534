import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Icon, Form } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import { useForm } from 'react-hook-form';
import { parse } from 'date-fns';

import { SedeContext } from '../../context/SedeContext';

import FormCheck from '../FormCheck/FormCheck';
import FormSelect from '../FormSelect/FormSelect';

import useToken from '../../hooks/useToken';
import api from '../../api/api';

function FiltroDespachos({ visible, onClose, onAccept }) {
  const [isLoading, setIsLoading] = useState(false);
  const [fechaDesde, setFechaDesde] = useState('');
  const [fechaHasta, setFechaHasta] = useState('');
  const [surtidores, setSurtidores] = useState([]);
  const [flotas, setFlotas] = useState([]);
  const [productos, setProductos] = useState([]);

  const { token } = useToken();

  const { sede } = useContext(SedeContext);

  const { control, handleSubmit, setValue } = useForm();

  const fecthProductos = () => {
    api
      .get('productos/', { headers: { Authorization: `Token ${token}` } })
      .then((response) => {
        const prodData = response.data;
        const prodOptions = [];

        prodOptions.push({ key: -1, text: 'Todos', value: -1 });

        prodData.forEach((p) => {
          prodOptions.push({
            key: p.id,
            text: p.nombre,
            value: p.id,
          });
        });

        setProductos(prodOptions);
        // setHasError(false);
      })
      .catch(() => {
        // setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchFlotas = () =>
    api
      .get(`flotas/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        const flotasData = response.data;
        const flotasOptions = [];

        flotasOptions.push({ key: -1, text: 'Todas', value: -1 });

        flotasData.forEach((f) => {
          flotasOptions.push({
            key: f.id,
            text: f.nombre,
            value: f.id,
          });
        });

        setFlotas(flotasOptions);
      });

  const fetchSurtidores = () =>
    api
      .get(`surtidores/`, {
        headers: { Authorization: `Token ${token}` },
        params: { sede: sede.codigo },
      })
      .then((response) => {
        const surtData = response.data;
        const surtOptions = [];

        surtOptions.push({ key: -1, text: 'Todos', value: -1 });

        surtData.forEach((surt) => {
          surtOptions.push({
            key: surt.id,
            text: `Surtidor ${surt.display_id}`,
            value: surt.display_id,
          });
        });

        setSurtidores(surtOptions);
      });

  const handleDateChange = (e, { name, value }) => {
    if (name === 'desde') {
      setFechaDesde(value);
    } else {
      setFechaHasta(value);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchSurtidores();
    fetchFlotas();
    fecthProductos();

    setValue('surtidores', [-1]);
    setValue('productos', [-1]);
    setValue('flota', -1);
  }, [visible]);

  const submitForm = (data) => {
    let params;

    if (fechaDesde !== '' && fechaHasta !== '') {
      const fd = parse(fechaDesde, 'dd-MM-yyyy HH:mm', new Date());
      const fh = parse(fechaHasta, 'dd-MM-yyyy HH:mm', new Date());

      // Convertir a ISO sin tener en cuenta la TZ
      const fdtz = new Date(fd.getTime() - fd.getTimezoneOffset() * 60 * 1000);
      const fhtz = new Date(fh.getTime() - fh.getTimezoneOffset() * 60 * 1000);

      let fdesde = fdtz.toISOString();
      let fhasta = fhtz.toISOString();

      params = {
        desde: fdesde,
        hasta: fhasta,
        surtidores: data.surtidores.join(),
        productos: data.productos.join(),
        flota: data.flota,
        sede: sede.codigo,
      };
    } else {
      params = {
        surtidores: data.surtidores.join(),
        // surtidores: data.surtidores,
        productos: data.productos.join(),
        flota: data.flota,
        sede: sede.codigo,
      };
    }

    let flota;

    if (data.flota == -1) flota = '';
    else flota = flotas.find((f) => f.value == data.flota).text;

    onClose();
    onAccept(params, data.mostrar_interno, data.mostrar_playero, flota);
  };

  return (
    <Modal dimmer="inverted" open={visible} size="tiny">
      <Modal.Header>Busqueda de despachos</Modal.Header>
      <Modal.Content>
        <Form loading={isLoading} onSubmit={handleSubmit(submitForm)} id="formFiltros">
          <Form.Group widths="equal">
            <FormSelect
              name="surtidores"
              label="Surtidor"
              placeholder="Surtidor"
              fluid
              multiple
              selection
              control={control}
              options={surtidores}
            />
            <FormSelect
              name="productos"
              label="Producto"
              placeholder="Producto"
              fluid
              multiple
              selection
              control={control}
              options={productos}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <DateTimeInput
              label="Desde"
              popupPosition="bottom left"
              autoComplete="off"
              closable
              clearable
              fluid
              clearIcon={<Icon name="remove" color="red" />}
              name="desde"
              placeholder="Desde"
              onChange={handleDateChange}
              value={fechaDesde}
              preserveViewMode={false}
            />
            <DateTimeInput
              label="Hasta"
              popupPosition="bottom left"
              autoComplete="off"
              closable
              clearable
              fluid
              clearIcon={<Icon name="remove" color="red" />}
              name="hasta"
              placeholder="Hasta"
              onChange={handleDateChange}
              value={fechaHasta}
              minDate={fechaDesde}
              preserveViewMode={false}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <FormSelect
              name="flota"
              label="Flota"
              placeholder="Flota"
              fluid
              selection
              control={control}
              options={flotas}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <FormCheck name="mostrar_interno" control={control} label="Mostrar Interno" />
            <FormCheck name="mostrar_playero" control={control} label="Mostrar Playero" />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button form="formFiltros" primary>
          Buscar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

FiltroDespachos.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  //   movimiento: PropTypes.object.isRequired,
};

export default FiltroDespachos;
